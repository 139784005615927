import React from "react";

const NoAccessPage = () => {
  return (
    <div style={{ padding: "2rem" }}>
      <h1>No Access</h1>
      <p>
        You do not have permission to view this page. Please contact the
        administrator if you think this is a mistake.
      </p>
    </div>
  );
};

export default NoAccessPage;
