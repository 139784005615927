import React, { useEffect, useState } from "react";
import axios from "axios";

const Test = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const clientUrl = process.env.REACT_APP_CLIENT_URL;
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

    const fetchData = async () => {
      try {
        const authString = `${clientId}:${clientSecret}`;
        const encodedAuth = btoa(authString);

        const payload = {
          string: "john",
        };

        const response = await axios.post(
          `${clientUrl}/ws/rest/getTasks`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${encodedAuth}`,
            },
          }
        );

        setData(response.data);
      } catch (error) {
        console.error(
          "Error posting data:",
          error.response || error.message || error
        );
        setError(
          error.response?.data || error.message || "Failed to post data."
        );
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <p className="text-red-500">Error: {error}</p>;
  }

  if (!data) {
    return <p className="text-gray-500">Loading...</p>;
  }

  return (
    <div className="p-4 space-y-6">
      {data[0]?.map((group, groupIndex) =>
        group.tasks.map((task, taskIndex) => (
          <div
            key={`task-${groupIndex}-${taskIndex}`}
            className="border border-gray-200 p-4 rounded-md shadow-sm"
          >
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold text-gray-800">
                {task.title}
              </h3>
              <p className="text-sm text-gray-500">Due: {task.dueDate}</p>
            </div>
            <div className="mt-3">
              <h4 className="text-sm font-medium text-gray-700">Objectives:</h4>
              <ul className="mt-2 space-y-2">
                {task.objectives.map((objective, objectiveIndex) => (
                  <li
                    key={`objective-${taskIndex}-${objectiveIndex}`}
                    className="flex items-center space-x-2"
                  >
                    <span
                      className={`h-3 w-3 rounded-full ${
                        objective.completed ? "bg-green-500" : "bg-red-500"
                      }`}
                    ></span>
                    <p className="text-sm text-gray-700">
                      {objective.description}{" "}
                      <span className="text-xs text-gray-500">
                        ({objective.completed ? "Completed" : "Pending"})
                      </span>
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Test;
