// AdminDashboard.jsx

import React, { useState, useEffect } from "react";
import {
  XCircleIcon,
  PencilAltIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline";
import Loader from "../../components/Loader";

// Helper functions
function userHasPermission(user, groups, sectionName, subtaskName) {
  const userGroups = groups.filter((g) => user.groupIds.includes(g.id));
  for (const group of userGroups) {
    for (const section of group.permissions) {
      if (section.section === sectionName) {
        for (const subtask of section.subtasks) {
          if (subtask.subtask === subtaskName && subtask.flag === true) {
            return true;
          }
        }
      }
    }
  }
  return false;
}

function userHasAdminAccess(user, groups) {
  return userHasPermission(user, groups, "Admin", "Admin");
}

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);

  const [levels, setLevels] = useState([]);
  const [levelsLoading, setLevelsLoading] = useState(true);

  const [groups, setGroups] = useState([]);
  const [groupsLoading, setGroupsLoading] = useState(true);

  const userEmail = "edoyle@miagen.com";
  const [currentUserGroups, setCurrentUserGroups] = useState([]);

  const [newUser, setNewUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    groupIds: [],
    levels: [],
    flag: "true",
  });

  const [editingUser, setEditingUser] = useState(null);
  const [showUserModal, setShowUserModal] = useState(false);
  const [userError, setUserError] = useState("");

  const [newGroup, setNewGroup] = useState({
    name: "",
    description: "",
    enabled: true,
    memberIds: [],
    permissions: [],
  });

  const [editingGroup, setEditingGroup] = useState(null);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);
  const [groupIdToDelete, setGroupIdToDelete] = useState(null);

  const [permissionsTemplate, setPermissionsTemplate] = useState(null);
  const [permissionsLoading, setPermissionsLoading] = useState(true);

  const clientUrl = process.env.REACT_APP_CLIENT_URL;
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

  const authString = `${clientId}:${clientSecret}`;
  const encodedAuth = btoa(authString);

  const fetchLevels = async () => {
    try {
      const response = await fetch(`${clientUrl}/ws/rest/getLevels`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
        body: JSON.stringify({ string: userEmail }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Levels Error Response:", errorText);
        throw new Error("Failed to fetch levels");
      }

      const data = await response.json();
      const levelsArray =
        Array.isArray(data) && Array.isArray(data[0]) ? data[0] : data;
      const levelNames = levelsArray.map((l) => l.name);
      setLevels(levelNames);
    } catch (error) {
      console.error("Error fetching levels:", error);
    } finally {
      setLevelsLoading(false);
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await fetch(`${clientUrl}/ws/rest/getGroups`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Groups Error Response:", errorText);
        throw new Error("Failed to fetch groups");
      }

      const data = await response.json();
      const transformedGroups = data.map((group) => ({
        id: parseInt(group.id),
        name: group.name,
        description: group.description,
        enabled: group.enabled === "true",
        memberIds: group.memberIds?.map(Number) || [],
        permissions: group.permissions || [],
      }));
      setGroups(transformedGroups);
    } catch (error) {
      console.error("Error fetching groups:", error);
    } finally {
      setGroupsLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${clientUrl}/ws/rest/getUsers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
        body: JSON.stringify({ string: userEmail }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Users Error Response:", errorText);
        throw new Error("Failed to fetch users");
      }

      const data = await response.json();
      const usersArray =
        Array.isArray(data) && Array.isArray(data[0]) ? data[0] : [];

      const transformedUsers = usersArray.map((user) => ({
        id: parseInt(user.id),
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        groupIds: user.permission_groups?.map(Number) || [],
        levels: user.levels || [],
        flag: user.flag || "true",
      }));

      setUsers(transformedUsers);

      const loggedInUser = transformedUsers.find((u) => u.email === userEmail);
      if (loggedInUser) {
        setCurrentUserGroups(loggedInUser.groupIds);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setUsersLoading(false);
    }
  };

  const fetchPermissionsList = async () => {
    try {
      const response = await fetch(
        "https://miagendb9091-miagen.msappproxy.net/ws/rest/getPermissionsList",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${encodedAuth}`,
          },
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Permissions Error Response:", errorText);
        throw new Error("Failed to fetch permissions list");
      }

      const data = await response.json();
      if (Array.isArray(data) && data.length > 0 && data[0].permissions) {
        setPermissionsTemplate(data[0].permissions);
      } else {
        setPermissionsTemplate([]);
      }
    } catch (error) {
      console.error("Error fetching permissions list:", error);
      setPermissionsTemplate([]);
    } finally {
      setPermissionsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchLevels();
      await fetchGroups();
      await fetchUsers();
      await fetchPermissionsList();
    };
    fetchData();
  }, []);

  const sendUserDataToBackend = async (user) => {
    const userData = {
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      permissions: [
        {
          levels: user.levels,
          groups: user.groupIds.map((id) => id.toString()),
        },
      ],
      flag: user.flag || "true",
    };

    if (user.id) {
      userData.id = user.id.toString();
    }

    const response = await fetch(`${clientUrl}/ws/rest/addUser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${encodedAuth}`,
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText);
    }
  };

  const deleteUserFromBackend = async (userId) => {
    const response = await fetch(`${clientUrl}/ws/rest/deleteUser/${userId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${encodedAuth}`,
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText);
    }
  };

  const handleOpenUserModal = (user = null) => {
    setUserError(""); // Reset error when opening modal
    if (user && user.flag === "false") {
      return;
    }

    if (user) {
      setEditingUser(user);
    } else {
      setNewUser({
        firstName: "",
        lastName: "",
        email: "",
        groupIds: [],
        levels: [],
        flag: "true",
      });
    }
    setShowUserModal(true);
  };

  const handleCloseUserModal = () => {
    setEditingUser(null);
    setShowUserModal(false);
    setUserError("");
  };

  const addUser = async () => {
    if (
      !newUser.firstName.trim() ||
      !newUser.lastName.trim() ||
      !newUser.email.trim()
    )
      return;

    try {
      await sendUserDataToBackend(newUser);
      await fetchUsers();
      setNewUser({
        firstName: "",
        lastName: "",
        email: "",
        groupIds: [],
        levels: [],
        flag: "true",
      });
      setShowUserModal(false);
    } catch (error) {
      console.error("Error adding user:", error);
      if (error.message.includes("already exists")) {
        setUserError("User already exists. Please use a different email.");
      } else {
        setUserError("An error occurred while adding the user.");
      }
    }
  };

  const updateUser = async () => {
    if (
      !editingUser.firstName.trim() ||
      !editingUser.lastName.trim() ||
      !editingUser.email.trim()
    )
      return;

    try {
      await sendUserDataToBackend(editingUser);
      await fetchUsers();
      setEditingUser(null);
      setShowUserModal(false);
    } catch (error) {
      console.error("Error updating user:", error);
      if (error.message.includes("already exists")) {
        setUserError("User already exists. Please use a different email.");
      } else {
        setUserError("An error occurred while updating the user.");
      }
    }
  };

  const deleteUser = async (userId) => {
    try {
      await deleteUserFromBackend(userId);
      await fetchUsers();
      setGroups(
        groups.map((group) => ({
          ...group,
          memberIds: group.memberIds?.filter((id) => id !== userId) || [],
        }))
      );
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const toggleGroupAssignment = (groupId) => {
    if (editingUser) {
      const isAssigned = editingUser.groupIds.includes(groupId);
      const updatedGroupIds = isAssigned
        ? editingUser.groupIds.filter((id) => id !== groupId)
        : [...editingUser.groupIds, groupId];
      setEditingUser({ ...editingUser, groupIds: updatedGroupIds });
    } else {
      const isAssigned = newUser.groupIds.includes(groupId);
      const updatedGroupIds = isAssigned
        ? newUser.groupIds.filter((id) => id !== groupId)
        : [...newUser.groupIds, groupId];
      setNewUser({ ...newUser, groupIds: updatedGroupIds });
    }
  };

  const toggleLevelAssignment = (levelName) => {
    if (editingUser) {
      const isAssigned = editingUser.levels.includes(levelName);
      const updatedLevels = isAssigned
        ? editingUser.levels.filter((level) => level !== levelName)
        : [...editingUser.levels, levelName];
      setEditingUser({ ...editingUser, levels: updatedLevels });
    } else {
      const isAssigned = newUser.levels.includes(levelName);
      const updatedLevels = isAssigned
        ? newUser.levels.filter((level) => level !== levelName)
        : [...newUser.levels, levelName];
      setNewUser({ ...newUser, levels: updatedLevels });
    }
  };

  const sendGroupDataToBackend = async (group) => {
    // Transform permissions to the required format:
    // Instead of sending flags, we send only subtasks that are 'true'.
    const preparedPermissions = group.permissions.map((permSection) => {
      const selectedSubtasks = permSection.subtasks
        .filter((st) => st.flag === true)
        .map((st) => st.subtask);

      return {
        section: permSection.section,
        subtasks: selectedSubtasks,
      };
    });

    const groupData = {
      name: group.name,
      description: group.description,
      permissions: preparedPermissions,
      enabled: group.enabled,
      memberIds: group.memberIds,
    };

    if (group.id) {
      groupData.id = group.id.toString();
    }

    const response = await fetch(`${clientUrl}/ws/rest/addGroup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${encodedAuth}`,
      },
      body: JSON.stringify(groupData),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText);
    }
  };

  const deleteGroupFromBackend = async (groupId) => {
    const payload = { string: groupId.toString() };

    const response = await fetch(`${clientUrl}/ws/rest/deleteGroup`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${encodedAuth}`,
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText);
    }
  };

  const confirmDeleteGroup = (groupId) => {
    setGroupIdToDelete(groupId);
    setShowDeleteGroupModal(true);
  };

  const handleCloseDeleteGroupModal = () => {
    setGroupIdToDelete(null);
    setShowDeleteGroupModal(false);
  };

  const handleConfirmDeleteGroup = async () => {
    if (!groupIdToDelete) return;
    try {
      await deleteGroupFromBackend(groupIdToDelete);
      await fetchGroups();
      setUsers(
        users.map((user) => ({
          ...user,
          groupIds: user.groupIds.filter((id) => id !== groupIdToDelete),
        }))
      );
    } catch (error) {
      console.error("Error deleting group:", error);
    } finally {
      handleCloseDeleteGroupModal();
    }
  };

  const toggleGroupEnabled = async (groupId) => {
    try {
      const group = groups.find((g) => g.id === groupId);
      if (group) {
        const updatedGroup = { ...group, enabled: !group.enabled };
        await sendGroupDataToBackend(updatedGroup);
        await fetchGroups();
      }
    } catch (error) {
      console.error("Error toggling group enabled state:", error);
    }
  };

  const toggleSubtaskFlag = (sectionName, subtaskName) => {
    const currentGroup = editingGroup || newGroup;

    const updatedPermissions = currentGroup.permissions.map((permSection) => {
      if (permSection.section === sectionName) {
        const updatedSubtasks = permSection.subtasks.map((st) => {
          if (st.subtask === subtaskName) {
            return { ...st, flag: !st.flag };
          }
          return st;
        });
        return { ...permSection, subtasks: updatedSubtasks };
      }
      return permSection;
    });

    if (editingGroup) {
      setEditingGroup({ ...editingGroup, permissions: updatedPermissions });
    } else {
      setNewGroup({ ...newGroup, permissions: updatedPermissions });
    }
  };

  const handleOpenGroupModal = (group = null) => {
    if (group) {
      // Editing existing group
      setEditingGroup(group);
      setShowGroupModal(true);
    } else {
      // Adding a new group
      if (permissionsTemplate && permissionsTemplate.length > 0) {
        // Clone the permissions template and ensure flags are initially false
        const clonedPermissions = JSON.parse(
          JSON.stringify(permissionsTemplate)
        );
        // Ensure all flags are false (just in case)
        clonedPermissions.forEach((section) => {
          section.subtasks.forEach((st) => {
            st.flag = false;
          });
        });

        setNewGroup({
          name: "",
          description: "",
          enabled: true,
          memberIds: [],
          permissions: clonedPermissions,
        });
      }
      setEditingGroup(null);
      setShowGroupModal(true);
    }
  };

  const handleCloseGroupModal = () => {
    setEditingGroup(null);
    setShowGroupModal(false);
  };

  const addGroup = async () => {
    if (!newGroup.name.trim()) return;

    try {
      await sendGroupDataToBackend(newGroup);
      await fetchGroups();
      setNewGroup({
        name: "",
        description: "",
        enabled: true,
        memberIds: [],
        permissions: [],
      });
      setShowGroupModal(false);
    } catch (error) {
      console.error("Error adding group:", error);
    }
  };

  const updateGroup = async () => {
    if (!editingGroup.name.trim()) return;

    try {
      await sendGroupDataToBackend(editingGroup);
      await fetchGroups();
      setEditingGroup(null);
      setShowGroupModal(false);
    } catch (error) {
      console.error("Error updating group:", error);
    }
  };

  if (usersLoading || groupsLoading || levelsLoading || permissionsLoading) {
    return <Loader />;
  }

  const loggedInUser = users.find((u) => u.email === userEmail);
  const canAccessAdmin = loggedInUser
    ? userHasAdminAccess(loggedInUser, groups)
    : false;

  if (!canAccessAdmin) {
    return (
      <div className="p-6 text-red-600 font-semibold">
        You do not have access to this page.
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="p-6 max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold mb-8 text-gray-900">
          Admin Dashboard
        </h1>

        {/* Users Section */}
        <div className="mb-16">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-2xl font-semibold text-gray-800">
              Manage Users
            </h2>
            <button
              onClick={() => handleOpenUserModal()}
              className="inline-flex items-center bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition"
            >
              <PlusCircleIcon className="w-5 h-5 mr-2" />
              Add User
            </button>
          </div>

          <div className="overflow-x-auto bg-white shadow-md rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-sm font-semibold text-gray-700 uppercase tracking-wider">
                    First Name
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-semibold text-gray-700 uppercase tracking-wider">
                    Last Name
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-semibold text-gray-700 uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-semibold text-gray-700 uppercase tracking-wider">
                    Groups
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-semibold text-gray-700 uppercase tracking-wider">
                    Levels
                  </th>
                  <th className="px-6 py-3 text-center text-sm font-semibold text-gray-700 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {users.map((user) => {
                  const userGroups = user.groupIds
                    .map(
                      (groupId) => groups.find((g) => g.id === groupId)?.name
                    )
                    .filter(Boolean);

                  const canEditUser =
                    user.flag === "true" &&
                    currentUserGroups.some((groupId) =>
                      user.groupIds.includes(groupId)
                    );

                  return (
                    <tr
                      key={user.id}
                      className={`hover:bg-gray-50 transition ${
                        user.flag === "false" ? "opacity-50" : ""
                      }`}
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-gray-800 font-medium">
                        {user.firstName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-800 font-medium">
                        {user.lastName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-700">
                        {user.email}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-700">
                        {userGroups.join(", ")}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-700">
                        {user.levels.join(", ")}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-center">
                        {canEditUser && (
                          <button
                            onClick={() => handleOpenUserModal(user)}
                            className="text-indigo-500 hover:text-indigo-700 mr-4"
                          >
                            <PencilAltIcon className="w-5 h-5 inline" />
                          </button>
                        )}
                        <button
                          onClick={() => deleteUser(user.id)}
                          className="text-red-500 hover:text-red-700"
                        >
                          <XCircleIcon className="w-5 h-5 inline" />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        {/* Groups Section */}
        <div>
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-2xl font-semibold text-gray-800">
              Manage Groups
            </h2>
            <button
              onClick={() => handleOpenGroupModal()}
              className="inline-flex items-center bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition"
            >
              <PlusCircleIcon className="w-5 h-5 mr-2" />
              Add Group
            </button>
          </div>

          <div className="overflow-x-auto bg-white shadow-md rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-sm font-semibold text-gray-700 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-semibold text-gray-700 uppercase tracking-wider">
                    Description
                  </th>
                  <th className="px-6 py-3 text-center text-sm font-semibold text-gray-700 uppercase tracking-wider">
                    Enabled
                  </th>
                  <th className="px-6 py-3 text-center text-sm font-semibold text-gray-700 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {groups.map((group) => (
                  <tr
                    key={group.id || group.name}
                    className="hover:bg-gray-50 transition"
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-gray-800 font-medium">
                      {group.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-gray-700">
                      {group.description}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      <button
                        onClick={() => toggleGroupEnabled(group.id)}
                        className={`px-3 py-1 rounded-full text-sm font-medium transition ${
                          group.enabled
                            ? "bg-green-100 text-green-800 hover:bg-green-200"
                            : "bg-red-100 text-red-800 hover:bg-red-200"
                        }`}
                      >
                        {group.enabled ? "Enabled" : "Disabled"}
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      <button
                        onClick={() => handleOpenGroupModal(group)}
                        className="text-indigo-500 hover:text-indigo-700 mr-4"
                      >
                        <PencilAltIcon className="w-5 h-5 inline" />
                      </button>
                      <button
                        onClick={() => confirmDeleteGroup(group.id)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <XCircleIcon className="w-5 h-5 inline" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* User Modal */}
        {showUserModal && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen px-4">
              <div className="fixed inset-0 bg-gray-500 opacity-50"></div>
              <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-2xl w-full">
                <div className="bg-white px-6 py-4 border-b border-gray-200 flex items-center justify-between">
                  <h3 className="text-xl font-medium text-gray-900">
                    {editingUser ? "Edit User" : "Add New User"}
                  </h3>
                  <button
                    onClick={handleCloseUserModal}
                    className="text-gray-400 hover:text-gray-600"
                  >
                    <XCircleIcon className="w-6 h-6" />
                  </button>
                </div>

                <div className="px-6 py-4">
                  {userError && (
                    <div className="mb-4 text-red-600 font-medium">
                      {userError}
                    </div>
                  )}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* User Info Fields */}
                    <div className="space-y-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          First Name
                        </label>
                        <input
                          type="text"
                          value={
                            editingUser
                              ? editingUser.firstName
                              : newUser.firstName
                          }
                          onChange={(e) =>
                            editingUser
                              ? setEditingUser({
                                  ...editingUser,
                                  firstName: e.target.value,
                                })
                              : setNewUser({
                                  ...newUser,
                                  firstName: e.target.value,
                                })
                          }
                          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Last Name
                        </label>
                        <input
                          type="text"
                          value={
                            editingUser
                              ? editingUser.lastName
                              : newUser.lastName
                          }
                          onChange={(e) =>
                            editingUser
                              ? setEditingUser({
                                  ...editingUser,
                                  lastName: e.target.value,
                                })
                              : setNewUser({
                                  ...newUser,
                                  lastName: e.target.value,
                                })
                          }
                          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Email
                        </label>
                        <input
                          type="email"
                          value={
                            editingUser ? editingUser.email : newUser.email
                          }
                          onChange={(e) =>
                            editingUser
                              ? setEditingUser({
                                  ...editingUser,
                                  email: e.target.value,
                                })
                              : setNewUser({
                                  ...newUser,
                                  email: e.target.value,
                                })
                          }
                          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                        />
                      </div>
                    </div>

                    {/* Assignments */}
                    <div className="space-y-4">
                      <div className="border rounded-md p-4 max-h-48 overflow-auto">
                        <h4 className="text-sm font-medium text-gray-700 mb-2">
                          Assign to Groups
                        </h4>
                        <div className="space-y-2">
                          {groups.map((group) => {
                            const isAssigned = (
                              editingUser
                                ? editingUser.groupIds
                                : newUser.groupIds
                            ).includes(group.id);
                            return (
                              <label
                                key={group.id}
                                className="flex items-center space-x-2"
                              >
                                <input
                                  type="checkbox"
                                  checked={isAssigned}
                                  onChange={() =>
                                    toggleGroupAssignment(group.id)
                                  }
                                  className="form-checkbox h-4 w-4 text-indigo-600"
                                />
                                <span className="text-gray-700 text-sm">
                                  {group.name}
                                </span>
                              </label>
                            );
                          })}
                        </div>
                      </div>

                      <div className="border rounded-md p-4 max-h-48 overflow-auto">
                        <h4 className="text-sm font-medium text-gray-700 mb-2">
                          Assign Levels
                        </h4>
                        <div className="space-y-2">
                          {levels.map((level) => {
                            const isAssigned = (
                              editingUser ? editingUser.levels : newUser.levels
                            ).includes(level);
                            return (
                              <label
                                key={level}
                                className="flex items-center space-x-2"
                              >
                                <input
                                  type="checkbox"
                                  checked={isAssigned}
                                  onChange={() => toggleLevelAssignment(level)}
                                  className="form-checkbox h-4 w-4 text-green-600"
                                />
                                <span className="text-gray-700 text-sm">
                                  {level}
                                </span>
                              </label>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 px-6 py-3 flex justify-end border-t border-gray-200">
                  <button
                    onClick={handleCloseUserModal}
                    className="bg-gray-200 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-300 transition mr-2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={editingUser ? updateUser : addUser}
                    className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition"
                  >
                    {editingUser ? "Update User" : "Add User"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Delete Group Confirmation Modal */}
        {showDeleteGroupModal && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen px-4">
              <div className="fixed inset-0 bg-gray-500 opacity-50"></div>
              <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-md w-full">
                <div className="p-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-4">
                    Confirm Deletion
                  </h3>
                  <p className="text-gray-700 mb-6">
                    Are you sure you want to delete this group? This action
                    cannot be undone.
                  </p>
                  <div className="flex justify-end gap-2">
                    <button
                      onClick={handleCloseDeleteGroupModal}
                      className="bg-gray-200 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-300 transition"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleConfirmDeleteGroup}
                      className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 transition"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Group Modal */}
        {showGroupModal && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen px-4">
              <div className="fixed inset-0 bg-gray-500 opacity-50"></div>

              <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-4xl w-full">
                <div className="bg-white px-6 py-4 border-b border-gray-200 flex items-center justify-between">
                  <h3 className="text-xl font-medium text-gray-900">
                    {editingGroup ? "Edit Group" : "Add New Group"}
                  </h3>
                  <button
                    onClick={handleCloseGroupModal}
                    className="text-gray-400 hover:text-gray-600"
                  >
                    <XCircleIcon className="w-6 h-6" />
                  </button>
                </div>

                <div className="px-6 py-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Left Column: Group Basic Info */}
                    <div className="space-y-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Group Name
                        </label>
                        <input
                          type="text"
                          value={
                            editingGroup ? editingGroup.name : newGroup.name
                          }
                          onChange={(e) =>
                            editingGroup
                              ? setEditingGroup({
                                  ...editingGroup,
                                  name: e.target.value,
                                })
                              : setNewGroup({
                                  ...newGroup,
                                  name: e.target.value,
                                })
                          }
                          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Description
                        </label>
                        <textarea
                          value={
                            editingGroup
                              ? editingGroup.description
                              : newGroup.description
                          }
                          onChange={(e) =>
                            editingGroup
                              ? setEditingGroup({
                                  ...editingGroup,
                                  description: e.target.value,
                                })
                              : setNewGroup({
                                  ...newGroup,
                                  description: e.target.value,
                                })
                          }
                          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                          rows="3"
                        ></textarea>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Enabled
                        </label>
                        <select
                          value={
                            editingGroup
                              ? editingGroup.enabled
                              : newGroup.enabled
                          }
                          onChange={(e) => {
                            const enabled = e.target.value === "true";
                            if (editingGroup) {
                              setEditingGroup({ ...editingGroup, enabled });
                            } else {
                              setNewGroup({ ...newGroup, enabled });
                            }
                          }}
                          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                        >
                          <option value="true">Enabled</option>
                          <option value="false">Disabled</option>
                        </select>
                      </div>
                    </div>

                    {/* Right Column: Permissions */}
                    {((editingGroup && editingGroup.permissions) ||
                      (newGroup && newGroup.permissions)) && (
                      <div className="space-y-4 p-4 border border-gray-300 rounded-md bg-gray-50 max-h-64 overflow-auto">
                        <h4 className="text-sm font-medium text-gray-700 mb-2">
                          Assign Permissions
                        </h4>
                        {(editingGroup
                          ? editingGroup.permissions
                          : newGroup.permissions
                        ).map((permSection, sectionIndex, arr) => (
                          <div
                            key={permSection.section}
                            className="mb-4 last:mb-0"
                          >
                            <h5 className="font-semibold text-gray-800 text-sm mb-2">
                              {permSection.section}
                            </h5>
                            <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                              {permSection.subtasks.map((subtaskObj) => (
                                <label
                                  key={subtaskObj.subtask}
                                  className="inline-flex items-center space-x-2"
                                >
                                  <input
                                    type="checkbox"
                                    checked={subtaskObj.flag}
                                    onChange={() =>
                                      toggleSubtaskFlag(
                                        permSection.section,
                                        subtaskObj.subtask
                                      )
                                    }
                                    className="form-checkbox h-4 w-4 text-indigo-600"
                                  />
                                  <span className="text-gray-700 text-sm">
                                    {subtaskObj.subtask}
                                  </span>
                                </label>
                              ))}
                            </div>
                            {sectionIndex < arr.length - 1 && (
                              <hr className="my-4 border-gray-200" />
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="bg-gray-50 px-6 py-3 flex justify-end border-t border-gray-200">
                  <button
                    onClick={handleCloseGroupModal}
                    className="bg-gray-200 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-300 transition mr-2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={editingGroup ? updateGroup : addGroup}
                    className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition"
                  >
                    {editingGroup ? "Update Group" : "Add Group"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
