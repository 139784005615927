import React, { useState } from "react";

const TopicRegister = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("");
  const [environmentalImpact, setEnvironmentalImpact] = useState("");
  const [businessImpact, setBusinessImpact] = useState("");
  const [notes, setNotes] = useState("");
  const [files, setFiles] = useState([]);

  const categories = {
    Environmental: {
      E1: {
        "E1.1": ["GHG Emissions", "Energy Management"],
        "E1.2": ["Water Management", "Waste Management"],
        "E1.3": ["Biodiversity Impact"],
      },
      E2: {
        "E2.1": ["Climate Adaptation", "Environmental Policy"],
        "E2.2": ["Environmental Management Systems"],
      },
    },
    Social: {
      S1: {
        "S1.1": ["Labor Rights", "Working Conditions"],
        "S1.2": ["Health and Safety"],
      },
    },
    Governance: {
      G1: {
        "G1.1": ["Board Structure", "Business Ethics"],
        "G1.2": ["Risk Management"],
      },
    },
  };

  const impactLevels = ["Negligible", "Moderate", "Significant", "Major"];

  const handleFileUpload = (event) => {
    const uploadedFiles = Array.from(event.target.files);
    setFiles((prev) => [...prev, ...uploadedFiles]);
  };

  const removeFile = (fileToRemove) => {
    setFiles(files.filter((file) => file !== fileToRemove));
  };

  const handleSubmit = () => {
    if (
      !selectedCategory ||
      !selectedSubCategory ||
      !selectedTopic ||
      !environmentalImpact ||
      !businessImpact
    ) {
      alert("Please fill in all fields");
      return;
    }

    const assessment = {
      category: selectedCategory,
      subCategory: selectedSubCategory,
      topic: selectedTopic,
      environmentalImpact,
      businessImpact,
      notes,
      files: files.map((f) => f.name),
      timestamp: new Date().toISOString(),
    };

    console.log("Assessment submitted:", assessment);
  };

  const SelectField = ({ label, value, onChange, options, placeholder }) => (
    <div className="relative">
      <label className="absolute -top-2 left-2 bg-white px-1 text-xs font-medium text-gray-600">
        {label}
      </label>
      <select
        value={value}
        onChange={onChange}
        className="w-full p-3 border border-gray-300 rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent appearance-none"
      >
        <option value="">{placeholder}</option>
        {options.map((opt) => (
          <option key={opt} value={opt}>
            {opt}
          </option>
        ))}
      </select>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-100">
      <h2 className="text-3xl font-bold text-gray-800 p-4 text-center">
        DMA Impact Assessment
      </h2>

      <div className="flex items-center justify-center">
        <div className="w-full max-w-2xl p-8 bg-white rounded-xl shadow-lg space-y-6">
          <div className="space-y-6">
            <SelectField
              label="Category"
              value={selectedCategory}
              onChange={(e) => {
                setSelectedCategory(e.target.value);
                setSelectedSubCategory("");
                setSelectedTopic("");
              }}
              options={Object.keys(categories)}
              placeholder="Select category"
            />

            {selectedCategory && (
              <SelectField
                label="Sub-category"
                value={selectedSubCategory}
                onChange={(e) => {
                  setSelectedSubCategory(e.target.value);
                  setSelectedTopic("");
                }}
                options={Object.keys(categories[selectedCategory])}
                placeholder="Select sub-category"
              />
            )}

            {selectedSubCategory && (
              <SelectField
                label="Topic"
                value={selectedTopic}
                onChange={(e) => setSelectedTopic(e.target.value)}
                options={Object.keys(
                  categories[selectedCategory][selectedSubCategory]
                ).flatMap(
                  (topic) =>
                    categories[selectedCategory][selectedSubCategory][topic]
                )}
                placeholder="Select topic"
              />
            )}

            {selectedTopic && (
              <>
                <SelectField
                  label="Impact on People and Environment"
                  value={environmentalImpact}
                  onChange={(e) => setEnvironmentalImpact(e.target.value)}
                  options={impactLevels}
                  placeholder="Select impact level"
                />

                <SelectField
                  label="Impact on Business Success"
                  value={businessImpact}
                  onChange={(e) => setBusinessImpact(e.target.value)}
                  options={impactLevels}
                  placeholder="Select impact level"
                />
              </>
            )}

            {environmentalImpact && businessImpact && (
              <div className="space-y-6">
                <div className="p-6 bg-gray-50 rounded-xl border border-gray-200">
                  <h3 className="font-semibold text-lg mb-4 text-gray-800">
                    Assessment Summary
                  </h3>
                  <div className="space-y-2 text-gray-600">
                    <p>
                      <span className="font-medium">Category:</span>{" "}
                      {selectedCategory}
                    </p>
                    <p>
                      <span className="font-medium">Sub-category:</span>{" "}
                      {selectedSubCategory}
                    </p>
                    <p>
                      <span className="font-medium">Topic:</span>{" "}
                      {selectedTopic}
                    </p>
                    <p>
                      <span className="font-medium">Environmental Impact:</span>{" "}
                      {environmentalImpact}
                    </p>
                    <p>
                      <span className="font-medium">Business Impact:</span>{" "}
                      {businessImpact}
                    </p>
                  </div>
                </div>

                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Decision Rationale
                    </label>
                    <textarea
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      className="w-full h-32 p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      placeholder="Add decision rationale  here..."
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Supporting Documents
                    </label>
                    <input
                      type="file"
                      multiple
                      onChange={handleFileUpload}
                      className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4
                               file:rounded-lg file:border-0 file:text-sm file:font-medium
                               file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                    />
                    {files.length > 0 && (
                      <div className="mt-2 space-y-2">
                        {files.map((file, index) => (
                          <div
                            key={index}
                            className="flex items-center justify-between p-2 bg-gray-50 rounded-lg"
                          >
                            <span className="text-sm text-gray-600">
                              {file.name}
                            </span>
                            <button
                              onClick={() => removeFile(file)}
                              className="text-red-500 hover:text-red-700"
                            >
                              Remove
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <button
                  onClick={handleSubmit}
                  className="px-6 py-2 bg-gradient-to-r from-blue-600 to-blue-700 text-white font-medium rounded-lg 
                           hover:from-blue-700 hover:to-blue-800 transition-all duration-200 shadow-md hover:shadow-lg 
                           focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2
                           mx-auto block"
                >
                  Submit Assessment
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopicRegister;
