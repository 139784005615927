import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ChevronRightIcon,
  ChevronDownIcon,
  PlusCircleIcon,
} from "@heroicons/react/solid";
import Loader from "../../components/Loader";

function buildTree(data) {
  const byCode = {};

  // Create a lookup table by code
  for (const item of data) {
    if (!item.code || typeof item.code !== "string" || !item.code.trim()) {
      console.warn(
        `Level with name "${item.name}" has an invalid or null code. Skipping this node.`
      );
      continue;
    }

    byCode[item.code] = { ...item, children: [] };
  }

  let root = null;

  // Link children to their parents
  for (const code in byCode) {
    const node = byCode[code];
    if (node.rolls_up_to && node.rolls_up_to.trim()) {
      const parent = byCode[node.rolls_up_to];
      if (parent) {
        parent.children.push(node);
      } else {
        console.warn(
          `Node "${node.name}" (code: "${node.code}") rolls up to "${node.rolls_up_to}", but no matching parent code found. This node will not appear in the tree.`
        );
      }
    } else {
      // This node is a candidate for the root
      if (root) {
        console.warn(
          `Multiple root nodes detected ("${root.name}" and "${node.name}"). Check data to ensure only one level has no parent.`
        );
      }
      root = node;
    }
  }

  if (!root) {
    console.warn(
      "No valid root node found. Make sure one node has an empty rolls_up_to field."
    );
  }

  return root;
}

const TreeNode = ({ node, selectedNode, setSelectedNode }) => {
  const [open, setOpen] = useState(true);
  const hasChildren = node.children && node.children.length > 0;
  const isSelected = selectedNode && selectedNode.code === node.code;

  return (
    <div className="ml-2">
      <div
        onClick={() => setSelectedNode(node)}
        className={`flex items-center px-2 py-1 rounded-md cursor-pointer select-none ${
          isSelected
            ? "bg-green-100 text-green-900 font-medium"
            : "hover:bg-gray-100 text-gray-700"
        }`}
      >
        {hasChildren ? (
          <button
            onClick={(e) => {
              e.stopPropagation();
              setOpen(!open);
            }}
            className="mr-1 focus:outline-none text-gray-500"
          >
            {open ? (
              <ChevronDownIcon className="w-4 h-4" />
            ) : (
              <ChevronRightIcon className="w-4 h-4" />
            )}
          </button>
        ) : (
          <span className="w-4 h-4 mr-1 inline-block"></span>
        )}
        {node.name}
      </div>
      {hasChildren && open && (
        <div className="ml-4 border-l border-gray-200 pl-3">
          {node.children.map((child) => (
            <TreeNode
              key={child.code}
              node={child}
              selectedNode={selectedNode}
              setSelectedNode={setSelectedNode}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const Modal = ({ open, onClose, title, children }) => {
  if (!open) return null;
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30">
      <div className="bg-white w-full max-w-sm rounded-lg shadow-lg p-6 relative">
        <button
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
          onClick={onClose}
        >
          &times;
        </button>
        <h2 className="text-xl font-semibold mb-4 text-gray-800">{title}</h2>
        {children}
      </div>
    </div>
  );
};

const LevelsTree = () => {
  const { user, isAuthenticated } = useAuth0();
  const [root, setRoot] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [levelsLoading, setLevelsLoading] = useState(true);
  const [levelsError, setLevelsError] = useState(null);

  const [showAddLevelModal, setShowAddLevelModal] = useState(false);
  const [newLevelName, setNewLevelName] = useState("");
  const [newLevelCode, setNewLevelCode] = useState("");

  useEffect(() => {
    if (!isAuthenticated || !user?.email) {
      console.warn("User not authenticated or no email available.");
      setLevelsLoading(false);
      setLevelsError("User is not authenticated or no email is available.");
      return;
    }

    const fetchLevels = async () => {
      setLevelsLoading(true);
      setLevelsError(null);

      try {
        const clientUrl = process.env.REACT_APP_CLIENT_URL;
        const clientId = process.env.REACT_APP_CLIENT_ID;
        const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

        const authString = `${clientId}:${clientSecret}`;
        const encodedAuth = btoa(authString);

        console.log("Fetching levels from API...");
        const response = await fetch(`${clientUrl}/ws/rest/getLevels`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${encodedAuth}`,
          },
          body: JSON.stringify({ string: user.email }),
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error("Levels fetch failed:", errorText);
          throw new Error(`Failed to fetch levels: ${errorText}`);
        }

        const data = await response.json();
        console.log("Raw getLevels data:", data);

        let levelsArray;
        if (Array.isArray(data)) {
          if (Array.isArray(data[0])) {
            levelsArray = data[0];
          } else {
            levelsArray = data;
          }
        } else {
          console.warn(
            "Data returned from API is not an array or expected format.",
            data
          );
          levelsArray = [];
        }

        console.log("Parsed levelsArray:", levelsArray);

        if (levelsArray.length === 0) {
          console.warn("No levels returned or invalid structure.");
          setLevelsError("No levels returned from API.");
          setLevelsLoading(false);
          return;
        }

        const treeRoot = buildTree(levelsArray);
        console.log("Constructed tree root:", treeRoot);

        if (!treeRoot) {
          console.warn(
            "No valid root level found. Check rolls_up_to fields or data structure."
          );
          setLevelsError("No valid root level found. Check data structure.");
          setLevelsLoading(false);
          return;
        }

        setRoot(treeRoot);
        setSelectedNode(treeRoot);
      } catch (err) {
        console.error("Error:", err);
        setLevelsError(err.message);
      } finally {
        setLevelsLoading(false);
      }
    };

    fetchLevels();
  }, [isAuthenticated, user]);

  const refreshLevels = async () => {
    if (!isAuthenticated || !user?.email) return;
    setLevelsLoading(true);
    setLevelsError(null);

    try {
      const clientUrl = process.env.REACT_APP_CLIENT_URL;
      const clientId = process.env.REACT_APP_CLIENT_ID;
      const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

      const authString = `${clientId}:${clientSecret}`;
      const encodedAuth = btoa(authString);

      console.log("Refreshing levels...");
      const response = await fetch(`${clientUrl}/ws/rest/getLevels`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
        body: JSON.stringify({ string: user.email }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to fetch levels: ${errorText}`);
      }

      const data = await response.json();
      console.log("Raw getLevels data (refresh):", data);

      let levelsArray;
      if (Array.isArray(data)) {
        if (Array.isArray(data[0])) {
          levelsArray = data[0];
        } else {
          levelsArray = data;
        }
      } else {
        console.warn("Data returned from API is not an array.", data);
        levelsArray = [];
      }

      const treeRoot = buildTree(levelsArray);
      console.log("Constructed tree root (refresh):", treeRoot);

      setRoot(treeRoot);
      setSelectedNode(treeRoot);
    } catch (err) {
      console.error("Error:", err);
      setLevelsError(err.message);
    } finally {
      setLevelsLoading(false);
    }
  };

  const handleAddLevel = async (e) => {
    e.preventDefault();
    if (!newLevelName.trim() || !newLevelCode.trim()) {
      alert("Please provide both a code and a name for the level.");
      return;
    }

    const payload = {
      name: newLevelName.trim(),
      code: newLevelCode.trim(),
      rolls_up_to: "Corporate", // Hardcoded default
      attributes: [],
    };

    try {
      const clientUrl = process.env.REACT_APP_CLIENT_URL;
      const clientId = process.env.REACT_APP_CLIENT_ID;
      const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

      const authString = `${clientId}:${clientSecret}`;
      const encodedAuth = btoa(authString);

      console.log("Adding new level with payload:", payload);
      const response = await fetch(`${clientUrl}/ws/rest/addLevel`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Failed to add level:", errorText);
        throw new Error(`Failed to add level: ${errorText}`);
      }

      await refreshLevels();
      setShowAddLevelModal(false);
      setNewLevelName("");
      setNewLevelCode("");
    } catch (err) {
      console.error(err);
      alert("Error adding level: " + err.message);
    }
  };

  if (levelsLoading) {
    return <Loader />;
  }

  if (levelsError) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-50">
        <p className="text-red-600">Error: {levelsError}</p>
      </div>
    );
  }

  return (
    <div className="h-screen flex flex-col overflow-hidden bg-gray-50">
      {/* Top Bar */}
      <div className="flex items-center justify-between px-4 py-3 bg-white border-b">
        <h1 className="text-lg font-bold text-gray-700">Levels Management</h1>
        <button
          onClick={() => setShowAddLevelModal(true)}
          className="flex items-center space-x-1 bg-green-600 hover:bg-green-700 text-white px-3 py-1 rounded-md text-sm font-medium"
        >
          <PlusCircleIcon className="w-5 h-5" />
          <span>Add Level</span>
        </button>
      </div>

      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar with tree */}
        <aside className="w-64 bg-white p-4 overflow-auto border-r">
          <h2 className="text-base font-semibold text-gray-700 mb-3">
            Hierarchy
          </h2>
          {root ? (
            <TreeNode
              node={root}
              selectedNode={selectedNode}
              setSelectedNode={setSelectedNode}
            />
          ) : (
            <p className="text-gray-500">No hierarchy to display</p>
          )}
        </aside>

        {/* Main content area for attributes */}
        <main className="flex-1 p-6 overflow-auto">
          {selectedNode ? (
            <div>
              <h1 className="text-xl font-semibold text-gray-800 mb-4">
                {selectedNode.name}
              </h1>
              <h2 className="text-sm font-semibold text-gray-600 mb-2">
                Attributes
              </h2>
              {selectedNode.attributes && selectedNode.attributes.length > 0 ? (
                <div className="space-y-3">
                  {selectedNode.attributes.map((attr, idx) => (
                    <div key={idx}>
                      <span className="font-medium text-gray-700">
                        {attr.attribute_name}:
                      </span>{" "}
                      <span className="text-gray-800">
                        {attr.attribute_value}
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500">
                  No attributes available for this level.
                </p>
              )}
            </div>
          ) : (
            <div className="flex items-center justify-center h-full text-gray-500">
              Select a level to view its attributes
            </div>
          )}
        </main>
      </div>

      {/* Add Level Modal */}
      <Modal
        open={showAddLevelModal}
        onClose={() => setShowAddLevelModal(false)}
        title="Add New Level"
      >
        <form onSubmit={handleAddLevel} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Level Code
            </label>
            <input
              type="text"
              value={newLevelCode}
              onChange={(e) => setNewLevelCode(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              placeholder="e.g. Galway"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Level Name (Display Name)
            </label>
            <input
              type="text"
              value={newLevelName}
              onChange={(e) => setNewLevelName(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              placeholder="e.g. Galway"
              required
            />
          </div>

          <p className="text-sm text-gray-500">
            This new level will roll up to{" "}
            <span className="font-semibold">Corporate</span>.
          </p>
          <div className="flex justify-end space-x-3 pt-2">
            <button
              type="button"
              onClick={() => setShowAddLevelModal(false)}
              className="px-4 py-2 bg-gray-200 rounded-md text-gray-700 hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-green-600 hover:bg-green-700 text-white rounded-md"
            >
              Add Level
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default LevelsTree;
