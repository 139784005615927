// ProjectPlan.jsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Plus, Pencil, X, Paperclip, Lightbulb, Upload } from "lucide-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../components/Loader";
import { useAuth0 } from "@auth0/auth0-react";
import { UserNameContext } from "../../ProtectedRoute";

// Helper to check permissions
function userHasPermission(user, groups, sectionName, subtaskName) {
  const userGroups = groups.filter((g) => user.groupIds.includes(g.id));
  for (const group of userGroups) {
    for (const section of group.permissions) {
      if (section.section === sectionName) {
        for (const subtask of section.subtasks) {
          if (subtask.subtask === subtaskName && subtask.flag === true) {
            return true;
          }
        }
      }
    }
  }
  return false;
}

const ProjectPlan = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const userEmail = user?.email;

  const [tasks, setTasks] = useState([]);
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [newTaskTitle, setNewTaskTitle] = useState("");
  const [newObjective, setNewObjective] = useState("");
  const [newIndicator, setNewIndicator] = useState({
    indicator: "",
    baseline: "",
    target: "",
    timeline: "",
    responsible: "",
  });

  const [usersLoading, setUsersLoading] = useState(true);
  const [tasksLoading, setTasksLoading] = useState(true);
  const [groupsLoading, setGroupsLoading] = useState(true);

  const loading = usersLoading || tasksLoading || groupsLoading;

  const [filterType, setFilterType] = useState("all");
  const [assigneeFilter, setAssigneeFilter] = useState("all");

  const [loggedInUser, setLoggedInUser] = useState(null);

  const clientUrl = process.env.REACT_APP_CLIENT_URL;
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

  const authString = `${clientId}:${clientSecret}`;
  const encodedAuth = btoa(authString);

  useEffect(() => {
    if (!isAuthenticated || !userEmail) return;
    fetchUsers();
    fetchTasks();
    fetchGroups();
  }, [isAuthenticated, userEmail]);

  const fetchUsers = async () => {
    if (!userEmail) return;
    try {
      const response = await fetch(`${clientUrl}/ws/rest/getUsers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
        body: JSON.stringify({ string: userEmail }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch users");
      }

      const data = await response.json();
      console.log("Users response:", data);
      const usersArray = Array.isArray(data) && data.length > 0 ? data[0] : [];
      if (!Array.isArray(usersArray)) {
        console.error("Expected usersArray to be an array, got:", usersArray);
      }

      const transformedUsers = Array.isArray(usersArray)
        ? usersArray.map((user) => ({
            id: parseInt(user.id),
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            groupIds: user.permission_groups?.map(Number) || [],
            levels: user.levels || [],
            flag: user.flag || "true",
          }))
        : [];

      setUsers(transformedUsers);

      const loggedUser = transformedUsers.find((u) => u.email === userEmail);
      if (loggedUser) {
        setLoggedInUser(loggedUser);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      alert("Failed to fetch users.");
    } finally {
      setUsersLoading(false);
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await fetch(`${clientUrl}/ws/rest/getGroups`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Groups Error Response:", errorText);
        throw new Error("Failed to fetch groups");
      }

      const data = await response.json();
      console.log("Groups response:", data);
      const transformedGroups = Array.isArray(data)
        ? data.map((group) => ({
            id: parseInt(group.id),
            name: group.name,
            description: group.description,
            enabled: group.enabled === "true",
            memberIds: group.memberIds?.map(Number) || [],
            permissions: group.permissions || [],
          }))
        : [];

      setGroups(transformedGroups);
    } catch (error) {
      console.error("Error fetching groups:", error);
      alert("Failed to fetch groups.");
    } finally {
      setGroupsLoading(false);
    }
  };

  const fetchTasks = async () => {
    if (!userEmail) return;
    try {
      const payload = { string: userEmail };
      const response = await axios.post(
        `${clientUrl}/ws/rest/getTasks`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${encodedAuth}`,
          },
        }
      );

      console.log("Tasks response:", response.data);

      if (Array.isArray(response.data) && response.data.length > 0) {
        const groupsArray = response.data[0];
        if (Array.isArray(groupsArray)) {
          const fetchedTasks = groupsArray.flatMap(
            (group) => group.tasks || []
          );
          const transformedTasks = fetchedTasks.map((task) => ({
            ...task,
            dueDate: task.dueDate ? new Date(task.dueDate) : null,
            attachments: task.attachments || [],
            objectives: (task.objectives || []).map((objective) => ({
              ...objective,
              indicators: objective.indicators || [],
              showIndicatorForm: false,
            })),
          }));
          setTasks(transformedTasks);
        } else {
          console.error(
            "Expected response.data[0] to be an array, got:",
            groupsArray
          );
          setTasks([]);
        }
      } else {
        console.error(
          "response.data is not a non-empty array. Got:",
          response.data
        );
        setTasks([]);
      }
    } catch (error) {
      console.error("Error fetching tasks:", error.response || error.message);
      // If user has no tasks, just set tasks to empty
      setTasks([]);
    } finally {
      setTasksLoading(false);
    }
  };

  const updateTaskOnServer = async (updatedTask) => {
    try {
      // No ID generation on frontend, let backend handle it.
      const payload = { tasks: updatedTask };
      const serializedPayload = JSON.parse(JSON.stringify(payload));

      await axios.post(`${clientUrl}/ws/rest/addTask`, serializedPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
      });
    } catch (error) {
      console.error("Error updating task:", error.response || error.message);
    }
  };

  const getUserByEmail = (email) =>
    users.find((u) => u.email?.toLowerCase() === email?.toLowerCase());

  const getUserInitials = (user) => {
    if (!user) return "?";
    const initials = [];
    if (user.firstName) initials.push(user.firstName[0]);
    if (user.lastName) initials.push(user.lastName[0]);
    return initials.join("").toUpperCase();
  };

  const getUserName = (email) => {
    const user = getUserByEmail(email);
    return user ? `${user.firstName} ${user.lastName}` : email || "Unknown";
  };

  const canCreateTask =
    loggedInUser && groups.length > 0
      ? userHasPermission(loggedInUser, groups, "Project Plan", "Create Task")
      : false;

  const canEditTask =
    loggedInUser && groups.length > 0
      ? userHasPermission(loggedInUser, groups, "Project Plan", "Edit Task")
      : false;

  const addTask = () => {
    if (!newTaskTitle.trim()) return;
    if (!canCreateTask) {
      alert("You do not have permission to create tasks.");
      return;
    }

    const newTask = {
      // No id generated here
      title: newTaskTitle,
      completed: false,
      assignees: [],
      dueDate: null,
      priority: "None",
      objectives: [],
      attachments: [],
    };
    const updatedTasks = [...tasks, newTask];
    setTasks(updatedTasks);
    setNewTaskTitle("");
    updateTaskOnServer(newTask);
  };

  const selectTask = (taskId) => {
    const task = tasks.find((t) => t.id === taskId);
    setSelectedTask(task);
  };

  const updateTask = (updatedTask) => {
    setTasks((prevTasks) =>
      prevTasks.map((t) => (t.id === updatedTask.id ? updatedTask : t))
    );
    updateTaskOnServer(updatedTask);
    if (selectedTask && selectedTask.id === updatedTask.id) {
      setSelectedTask(updatedTask);
    }
  };

  const toggleTaskComplete = (taskId) => {
    if (!canEditTask) {
      alert("You do not have permission to edit tasks.");
      return;
    }

    const task = tasks.find((t) => t.id === taskId);
    const updatedTask = { ...task, completed: !task.completed };
    updateTask(updatedTask);
  };

  const addAssigneeToTask = (taskId, userEmail) => {
    if (!canEditTask) {
      alert("You do not have permission to edit tasks.");
      return;
    }

    const task = tasks.find((t) => t.id === taskId);
    const isAlreadyAssigned = task.assignees.includes(userEmail);
    const updatedAssignees = isAlreadyAssigned
      ? task.assignees
      : [...task.assignees, userEmail];
    const updatedTask = { ...task, assignees: updatedAssignees };
    updateTask(updatedTask);
  };

  const removeAssigneeFromTask = (taskId, userEmail) => {
    if (!canEditTask) {
      alert("You do not have permission to edit tasks.");
      return;
    }

    const task = tasks.find((t) => t.id === taskId);
    const updatedTask = {
      ...task,
      assignees: task.assignees.filter((email) => email !== userEmail),
    };
    updateTask(updatedTask);
  };

  const addObjective = (taskId) => {
    if (!newObjective.trim()) return;
    if (!canEditTask) {
      alert("You do not have permission to edit tasks.");
      return;
    }

    const task = tasks.find((t) => t.id === taskId);
    const updatedTask = {
      ...task,
      objectives: [
        ...task.objectives,
        {
          // No id generated here
          description: newObjective,
          completed: false,
          assignees: [],
          indicators: [],
          attachments: [],
          showIndicatorForm: false,
        },
      ],
    };
    updateTask(updatedTask);
    setNewObjective("");
  };

  const toggleObjectiveComplete = (taskId, objectiveId) => {
    if (!canEditTask) {
      alert("You do not have permission to edit tasks.");
      return;
    }

    const task = tasks.find((t) => t.id === taskId);
    const updatedObjectives = task.objectives.map((o) =>
      o.id === objectiveId ? { ...o, completed: !o.completed } : o
    );
    const updatedTask = { ...task, objectives: updatedObjectives };
    updateTask(updatedTask);
  };

  const toggleStakeholder = (taskId, objectiveId, userEmail) => {
    if (!canEditTask) {
      alert("You do not have permission to edit tasks.");
      return;
    }

    const task = tasks.find((t) => t.id === taskId);
    const updatedObjectives = task.objectives.map((o) => {
      if (o.id === objectiveId) {
        const current = new Set(o.assignees);
        current.has(userEmail)
          ? current.delete(userEmail)
          : current.add(userEmail);
        return { ...o, assignees: Array.from(current) };
      }
      return o;
    });
    const updatedTask = { ...task, objectives: updatedObjectives };
    updateTask(updatedTask);
  };

  const handleFileUpload = (taskId, event) => {
    if (!canEditTask) {
      alert("You do not have permission to edit tasks.");
      return;
    }

    const file = event.target.files[0];
    if (!file) return;
    const task = tasks.find((t) => t.id === taskId);
    const updatedTask = {
      ...task,
      attachments: [
        ...task.attachments,
        // No id generated here for attachments; the backend can handle it if needed
        { name: file.name, size: file.size, type: file.type },
      ],
    };
    updateTask(updatedTask);
  };

  const removeAttachment = (taskId, attachmentId) => {
    if (!canEditTask) {
      alert("You do not have permission to edit tasks.");
      return;
    }

    const task = tasks.find((t) => t.id === taskId);
    const updatedTask = {
      ...task,
      attachments: task.attachments.filter((att) => att.id !== attachmentId),
    };
    updateTask(updatedTask);
  };

  const formatFileSize = (bytes) => {
    if (bytes < 1024) return bytes + " B";
    if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(1) + " KB";
    return (bytes / (1024 * 1024)).toFixed(1) + " MB";
  };

  const handleDueDateChange = (taskId, date) => {
    if (!canEditTask) {
      alert("You do not have permission to edit tasks.");
      return;
    }

    const task = tasks.find((t) => t.id === taskId);
    const updatedTask = { ...task, dueDate: date };
    updateTask(updatedTask);
  };

  const addIndicator = (taskId, objectiveId) => {
    if (!canEditTask) {
      alert("You do not have permission to edit tasks.");
      return;
    }

    if (!newIndicator.indicator.trim()) return;
    const task = tasks.find((t) => t.id === taskId);
    const updatedObjectives = task.objectives.map((o) => {
      if (o.id === objectiveId) {
        return {
          ...o,
          indicators: [...o.indicators, newIndicator],
          showIndicatorForm: false,
        };
      }
      return o;
    });
    const updatedTask = { ...task, objectives: updatedObjectives };
    updateTask(updatedTask);
    setNewIndicator({
      indicator: "",
      baseline: "",
      target: "",
      timeline: "",
      responsible: "",
    });
  };

  const toggleIndicatorForm = (taskId, objectiveId) => {
    const task = tasks.find((t) => t.id === taskId);
    const updatedObjectives = task.objectives.map((o) => {
      if (o.id === objectiveId) {
        return { ...o, showIndicatorForm: !o.showIndicatorForm };
      }
      return o;
    });
    const updatedTask = { ...task, objectives: updatedObjectives };
    updateTask(updatedTask);
  };

  const getPriorityClasses = (priority) => {
    switch (priority) {
      case "High":
        return "bg-red-100 text-red-800";
      case "Medium":
        return "bg-yellow-100 text-yellow-800";
      case "Low":
        return "bg-green-100 text-green-800";
      default:
        return "bg-gray-200 text-gray-700";
    }
  };

  const filteredTasks = tasks.filter((task) => {
    if (filterType === "completed" && !task.completed) return false;
    if (filterType === "incomplete" && task.completed) return false;

    if (assigneeFilter === "unassigned" && task.assignees.length > 0)
      return false;
    if (assigneeFilter !== "all" && assigneeFilter !== "unassigned") {
      if (!task.assignees.includes(assigneeFilter)) return false;
    }

    return true;
  });

  if (loading || !userEmail) {
    return <Loader />;
  }

  return (
    <div className="h-screen flex overflow-hidden">
      {/* Left Panel */}
      <div className="w-2/3 border-r relative bg-white">
        <div className="sticky top-0 h-screen overflow-auto p-6 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-400">
          <div className="relative group inline-block">
            <h1 className="text-3xl font-bold text-gray-800 mb-4 cursor-help inline-block">
              Project Plan
            </h1>
            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-900 text-white text-xs rounded shadow opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none whitespace-nowrap">
              Detailed tasks, objectives, and timelines for the project
            </div>
          </div>

          <div className="flex gap-2 mb-6 mt-6">
            <input
              type="text"
              placeholder="Add new task"
              value={newTaskTitle}
              onChange={(e) => setNewTaskTitle(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && addTask()}
              className="flex-grow border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition text-gray-700"
            />
            <button
              onClick={addTask}
              disabled={!canCreateTask}
              className={`px-4 py-2 rounded-md transition flex items-center gap-2 font-medium ${
                canCreateTask
                  ? "bg-indigo-500 text-white hover:bg-indigo-600"
                  : "bg-gray-300 text-gray-500 cursor-not-allowed"
              }`}
            >
              <Plus className="w-5 h-5" />
              Add Task
            </button>
          </div>

          {/* Filters */}
          <div className="mb-4 flex items-center gap-2">
            <select
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
              className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition text-gray-700"
            >
              <option value="all">All Tasks</option>
              <option value="completed">Completed Tasks</option>
              <option value="incomplete">Incomplete Tasks</option>
            </select>

            <select
              value={assigneeFilter}
              onChange={(e) => setAssigneeFilter(e.target.value)}
              className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition text-gray-700"
            >
              <option value="all">All Assignees</option>
              <option value="unassigned">Unassigned</option>
              {users.map((user) => (
                <option key={user.email} value={user.email}>
                  {user.firstName} {user.lastName}
                </option>
              ))}
            </select>
          </div>

          <div className="bg-white rounded-md shadow-sm overflow-hidden border">
            <table className="min-w-full divide-y divide-gray-200 text-sm">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-3 text-left font-semibold text-gray-700">
                    Task Name
                  </th>
                  <th className="px-4 py-3 text-left font-semibold text-gray-700">
                    Assignees
                  </th>
                  <th className="px-4 py-3 text-left font-semibold text-gray-700">
                    Due Date
                  </th>
                  <th className="px-4 py-3 text-left font-semibold text-gray-700">
                    Priority
                  </th>
                  <th className="px-4 py-3"></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredTasks.map((task) => (
                  <tr
                    key={task.id}
                    className="hover:bg-gray-50 cursor-pointer"
                    onClick={() => selectTask(task.id)}
                  >
                    <td className="px-4 py-3 flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={task.completed}
                        onChange={(e) => {
                          e.stopPropagation();
                          toggleTaskComplete(task.id);
                        }}
                        disabled={!canEditTask}
                        className={`form-checkbox h-4 w-4 ${
                          canEditTask
                            ? "text-indigo-500"
                            : "text-gray-300 cursor-not-allowed"
                        }`}
                      />
                      <span
                        className={`${
                          task.completed
                            ? "line-through text-gray-500"
                            : "text-gray-800"
                        } font-medium`}
                      >
                        {task.title}
                      </span>
                      {task.attachments?.length > 0 && (
                        <span className="ml-2 text-gray-500 flex items-center gap-1 text-xs">
                          <Paperclip className="w-4 h-4" />
                          {task.attachments.length}
                        </span>
                      )}
                    </td>
                    <td className="px-4 py-3 text-gray-700">
                      {task.assignees.length === 0 ? (
                        <span className="text-gray-400 italic">None</span>
                      ) : (
                        <div className="flex -space-x-2">
                          {task.assignees.map((email) => {
                            const user = getUserByEmail(email);
                            return (
                              <div
                                key={email}
                                className="relative group inline-flex items-center justify-center w-6 h-6 rounded-full border-2 border-white text-xs font-medium bg-blue-100 text-blue-800"
                              >
                                {user ? getUserInitials(user) : "?"}
                                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 px-2 py-1 bg-gray-900 text-white text-xs rounded shadow opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none whitespace-nowrap">
                                  {getUserName(email)}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </td>
                    <td className="px-4 py-3 text-gray-700">
                      {task.dueDate ? (
                        task.dueDate.toLocaleDateString()
                      ) : (
                        <span className="text-gray-400 italic">No date</span>
                      )}
                    </td>
                    <td className="px-4 py-3">
                      <span
                        className={`px-2 py-0.5 rounded-full text-xs font-medium ${getPriorityClasses(
                          task.priority
                        )}`}
                      >
                        {task.priority}
                      </span>
                    </td>
                    <td className="px-4 py-3">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          selectTask(task.id);
                        }}
                        className={`p-1 rounded-full ${
                          canEditTask ? "hover:bg-gray-200" : "text-gray-300"
                        }`}
                      >
                        <Pencil className="w-4 h-4" />
                      </button>
                    </td>
                  </tr>
                ))}
                {filteredTasks.length === 0 && (
                  <tr>
                    <td
                      colSpan="5"
                      className="px-4 py-8 text-center text-gray-500"
                    >
                      No tasks match the selected filter.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Right Panel: Detail View */}
      <div
        className={`w-1/3 bg-gray-50 border-l transform transition-transform duration-300 ${
          selectedTask ? "translate-x-0" : "translate-x-full"
        } overflow-auto scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-400 flex flex-col h-screen`}
      >
        {selectedTask && (
          <div className="p-6 relative">
            <button
              onClick={() => setSelectedTask(null)}
              className="absolute top-4 right-4 p-2 hover:bg-gray-200 rounded-full"
              title="Close"
            >
              <X className="w-5 h-5 text-gray-600" />
            </button>

            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              {selectedTask.title}
            </h2>

            <div className="space-y-6 pb-20">
              {/* Assignees */}
              <div>
                <h3 className="font-semibold text-gray-800 mb-2">Assignees</h3>
                <div className="flex flex-wrap gap-2 mb-2">
                  {selectedTask.assignees.map((email) => {
                    const user = getUserByEmail(email);
                    return (
                      <div
                        key={email}
                        className="relative group inline-flex items-center gap-2 bg-blue-100 text-blue-800 px-3 py-1 rounded-full"
                      >
                        <div className="w-6 h-6 rounded-full bg-blue-200 flex items-center justify-center text-sm font-semibold">
                          {user ? getUserInitials(user) : "?"}
                        </div>
                        {user ? getUserName(email) : email}
                        <button
                          onClick={() =>
                            removeAssigneeFromTask(selectedTask.id, email)
                          }
                          className="p-1 hover:bg-blue-200 rounded-full"
                          disabled={!canEditTask}
                        >
                          <X className="w-4 h-4 text-blue-800" />
                        </button>
                      </div>
                    );
                  })}
                </div>
                <div>
                  <select
                    onChange={(e) => {
                      const val = e.target.value;
                      if (val) {
                        addAssigneeToTask(selectedTask.id, val);
                        e.target.value = "";
                      }
                    }}
                    className="border border-gray-300 rounded-md px-3 py-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition text-gray-700"
                    defaultValue=""
                    disabled={!canEditTask}
                  >
                    <option value="">+ Add Assignee</option>
                    {users
                      .filter((u) => !selectedTask.assignees.includes(u.email))
                      .map((u) => (
                        <option key={u.email} value={u.email}>
                          {u.firstName} {u.lastName}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              {/* Due Date */}
              <div>
                <h3 className="font-semibold text-gray-800 mb-2">Due Date</h3>
                <DatePicker
                  selected={selectedTask.dueDate}
                  onChange={(date) =>
                    handleDueDateChange(selectedTask.id, date)
                  }
                  dateFormat="MMMM d, yyyy"
                  placeholderText="Select a due date"
                  className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition text-gray-700"
                  disabled={!canEditTask}
                />
              </div>

              {/* Priority */}
              <div>
                <h3 className="font-semibold text-gray-800 mb-2">Priority</h3>
                <select
                  value={selectedTask.priority}
                  onChange={(e) => {
                    const updatedTask = {
                      ...selectedTask,
                      priority: e.target.value,
                    };
                    updateTask(updatedTask);
                  }}
                  className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition text-gray-700 w-full"
                  disabled={!canEditTask}
                >
                  <option value="None">None</option>
                  <option value="Low">Low</option>
                  <option value="Medium">Medium</option>
                  <option value="High">High</option>
                </select>
              </div>

              {/* Objectives */}
              <div>
                <div className="flex items-center gap-2 mb-4">
                  <Lightbulb className="w-5 h-5 text-yellow-500" />
                  <h3 className="font-semibold text-lg text-gray-800">
                    Objectives
                  </h3>
                </div>
                <div className="space-y-6 mb-4">
                  {selectedTask.objectives.map((objective) => (
                    <div
                      key={objective.id || Math.random()}
                      className="space-y-4"
                    >
                      <div className="flex items-center gap-4">
                        <input
                          type="checkbox"
                          checked={objective.completed}
                          onChange={() =>
                            toggleObjectiveComplete(
                              selectedTask.id,
                              objective.id
                            )
                          }
                          className="form-checkbox h-5 w-5 text-indigo-500"
                          disabled={!canEditTask}
                        />
                        <span
                          className={`font-medium ${
                            objective.completed
                              ? "line-through text-gray-400"
                              : "text-gray-800"
                          }`}
                        >
                          {objective.description}
                        </span>
                      </div>

                      {/* Objective Stakeholders */}
                      <div className="ml-9 flex flex-wrap gap-2">
                        {users.map((user) => {
                          const isSelected = objective.assignees.includes(
                            user.email
                          );
                          return (
                            <button
                              key={user.email}
                              className={`flex items-center gap-2 px-3 py-1 rounded-full text-sm font-medium transition ${
                                isSelected
                                  ? "bg-blue-100 text-blue-800"
                                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                              }`}
                              onClick={() =>
                                toggleStakeholder(
                                  selectedTask.id,
                                  objective.id,
                                  user.email
                                )
                              }
                              disabled={!canEditTask}
                            >
                              <div
                                className={`w-6 h-6 rounded-full flex items-center justify-center text-sm font-semibold ${
                                  isSelected
                                    ? "bg-blue-200 text-blue-800"
                                    : "bg-gray-300 text-gray-700"
                                }`}
                              >
                                {getUserInitials(user)}
                              </div>
                              {user.firstName}
                            </button>
                          );
                        })}
                      </div>

                      {/* Indicators */}
                      {objective.indicators?.map((ind, index) => (
                        <div
                          key={index}
                          className="p-3 bg-white rounded-md shadow border text-sm"
                        >
                          <p>
                            <strong>Indicator:</strong> {ind.indicator}
                          </p>
                          <p>
                            <strong>Baseline:</strong> {ind.baseline}
                          </p>
                          <p>
                            <strong>Target:</strong> {ind.target}
                          </p>
                          <p>
                            <strong>Timeline:</strong> {ind.timeline}
                          </p>
                          <p>
                            <strong>Responsible:</strong> {ind.responsible}
                          </p>
                        </div>
                      ))}

                      {!objective.showIndicatorForm && (
                        <button
                          onClick={() =>
                            toggleIndicatorForm(selectedTask.id, objective.id)
                          }
                          className="text-indigo-500 hover:underline text-sm"
                          disabled={!canEditTask}
                        >
                          + Add Indicator
                        </button>
                      )}

                      {objective.showIndicatorForm && (
                        <div className="mt-4">
                          <h4 className="font-medium mb-2">
                            Add Measurement Indicator
                          </h4>
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">
                            <input
                              type="text"
                              placeholder="Indicator"
                              value={newIndicator.indicator}
                              onChange={(e) =>
                                setNewIndicator({
                                  ...newIndicator,
                                  indicator: e.target.value,
                                })
                              }
                              className="border rounded-md p-2 w-full"
                              disabled={!canEditTask}
                            />
                            <input
                              type="text"
                              placeholder="Baseline"
                              value={newIndicator.baseline}
                              onChange={(e) =>
                                setNewIndicator({
                                  ...newIndicator,
                                  baseline: e.target.value,
                                })
                              }
                              className="border rounded-md p-2 w-full"
                              disabled={!canEditTask}
                            />
                            <input
                              type="text"
                              placeholder="Target"
                              value={newIndicator.target}
                              onChange={(e) =>
                                setNewIndicator({
                                  ...newIndicator,
                                  target: e.target.value,
                                })
                              }
                              className="border rounded-md p-2 w-full"
                              disabled={!canEditTask}
                            />
                            <input
                              type="text"
                              placeholder="Timeline"
                              value={newIndicator.timeline}
                              onChange={(e) =>
                                setNewIndicator({
                                  ...newIndicator,
                                  timeline: e.target.value,
                                })
                              }
                              className="border rounded-md p-2 w-full"
                              disabled={!canEditTask}
                            />
                            <input
                              type="text"
                              placeholder="Responsible"
                              value={newIndicator.responsible}
                              onChange={(e) =>
                                setNewIndicator({
                                  ...newIndicator,
                                  responsible: e.target.value,
                                })
                              }
                              className="border rounded-md p-2 w-full"
                              disabled={!canEditTask}
                            />
                          </div>
                          <div className="flex gap-2 mt-4">
                            <button
                              onClick={() =>
                                addIndicator(selectedTask.id, objective.id)
                              }
                              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition text-sm"
                              disabled={!canEditTask}
                            >
                              Add Indicator
                            </button>
                            <button
                              onClick={() =>
                                toggleIndicatorForm(
                                  selectedTask.id,
                                  objective.id
                                )
                              }
                              className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400 transition text-sm"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                {/* Add Objective */}
                <div className="flex gap-2">
                  <input
                    type="text"
                    placeholder="Add new objective"
                    value={newObjective}
                    onChange={(e) => setNewObjective(e.target.value)}
                    onKeyPress={(e) =>
                      e.key === "Enter" && addObjective(selectedTask.id)
                    }
                    className="flex-grow border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition"
                    disabled={!canEditTask}
                  />
                  <button
                    onClick={() => addObjective(selectedTask.id)}
                    className={`px-4 py-2 rounded-md transition flex items-center gap-2 font-medium ${
                      canEditTask
                        ? "bg-indigo-500 text-white hover:bg-indigo-600"
                        : "bg-gray-300 text-gray-500 cursor-not-allowed"
                    }`}
                    disabled={!canEditTask}
                  >
                    <Plus className="w-5 h-5" />
                    Add
                  </button>
                </div>
              </div>

              {/* Attachments */}
              <div>
                <div className="flex items-center gap-2 mb-4">
                  <Paperclip className="w-5 h-5 text-gray-600" />
                  <h3 className="font-semibold text-lg text-gray-800">
                    Attachments
                  </h3>
                </div>
                <div className="space-y-2 mb-4 text-sm">
                  {selectedTask.attachments.map((attachment, idx) => (
                    <div
                      key={idx}
                      className="flex items-center justify-between p-3 bg-white rounded-md border shadow-sm"
                    >
                      <div className="flex items-center gap-2">
                        <Paperclip className="w-5 h-5 text-gray-600" />
                        <span className="text-gray-800 font-medium">
                          {attachment.name}
                        </span>
                        <span className="text-gray-500 text-xs">
                          ({formatFileSize(attachment.size)})
                        </span>
                      </div>
                      <button
                        onClick={() =>
                          removeAttachment(selectedTask.id, attachment.id)
                        }
                        className="p-2 rounded-full hover:bg-gray-100 transition"
                        disabled={!canEditTask}
                      >
                        <X className="w-5 h-5 text-gray-600" />
                      </button>
                    </div>
                  ))}
                  {selectedTask.attachments.length === 0 && (
                    <div className="text-gray-500 italic">No attachments</div>
                  )}
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="file"
                    onChange={(e) => handleFileUpload(selectedTask.id, e)}
                    className="hidden"
                    id={`file-upload-${selectedTask.id}`}
                    disabled={!canEditTask}
                  />
                  <label
                    htmlFor={`file-upload-${selectedTask.id}`}
                    className={`cursor-pointer border border-gray-300 px-4 py-2 rounded-md transition flex items-center gap-2 font-medium text-gray-700 text-sm ${
                      canEditTask
                        ? "hover:bg-gray-100"
                        : "bg-gray-200 text-gray-400 cursor-not-allowed"
                    }`}
                  >
                    <Upload className="w-5 h-5 text-gray-600" />
                    Upload Files
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectPlan;
