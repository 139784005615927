// ProtectedRoute.js
import React, { useEffect, useState, createContext, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import Loader from "./components/Loader"; // Ensure this is a valid import

export const UserNameContext = createContext(null);

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading, user } = useAuth0();

  const [dbUserLoading, setDbUserLoading] = useState(true);
  const [dbUserExists, setDbUserExists] = useState(false);
  const [dbUserName, setDbUserName] = useState("");

  const clientUrl = process.env.REACT_APP_CLIENT_URL;
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

  const authString = `${clientId}:${clientSecret}`;
  const encodedAuth = btoa(authString);

  useEffect(() => {
    const checkUserInDB = async () => {
      try {
        if (!isAuthenticated || !user) {
          console.log("User not authenticated or user object missing.");
          setDbUserExists(false);
          setDbUserLoading(false);
          return;
        }

        const userEmail = user?.email;
        if (!userEmail) {
          console.log("No email found on authenticated user.");
          setDbUserExists(false);
          setDbUserLoading(false);
          return;
        }

        console.log("Checking user in DB with email:", userEmail);

        const response = await fetch(`${clientUrl}/ws/rest/getUsers`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${encodedAuth}`,
          },
          body: JSON.stringify({ string: userEmail }),
        });

        console.log("Response status:", response.status);

        if (!response.ok) {
          console.error("Failed to fetch users from the backend.");
          setDbUserExists(false);
          setDbUserLoading(false);
          return;
        }

        const data = await response.json();
        console.log("Fetched data:", data);

        const usersArray = Array.isArray(data[0]) ? data[0] : [];
        console.log("Parsed usersArray:", usersArray);

        // Find the user in the array that matches the authenticated user's email
        const dbUser = usersArray.find((u) => u.email === userEmail);

        if (dbUser) {
          setDbUserExists(true);
          const fullName = `${dbUser.first_name || ""} ${
            dbUser.last_name || ""
          }`.trim();
          setDbUserName(fullName);
        } else {
          setDbUserExists(false);
        }
      } catch (error) {
        console.error("Error checking user in db:", error);
        setDbUserExists(false);
      } finally {
        setDbUserLoading(false);
      }
    };

    if (!isLoading) {
      checkUserInDB();
    }
  }, [isAuthenticated, isLoading, user, clientUrl, encodedAuth]);

  if (isLoading || dbUserLoading) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    // User not authenticated, redirect to login
    return <Navigate to="/login" />;
  }

  if (!dbUserExists) {
    // User authenticated but not found in DB, redirect to no-access
    return <Navigate to="/no-access" />;
  }

  // If we reach here, user is authenticated and in DB
  return (
    <UserNameContext.Provider value={dbUserName}>
      {children}
    </UserNameContext.Provider>
  );
};

export default ProtectedRoute;
