// auth0Config.js
const isDev = window.location.hostname === "localhost";

export const auth0Config = {
  domain: "dev-p3sop4yoyco0bfzk.eu.auth0.com",
  clientId: "ZJbqCHQ5irdqOEv9ApPhgaT3PWpx5ScC",
  redirectUri: isDev
    ? "http://localhost:3000"
    : "https://greengenn-c2e8axbsgxdzehe2.northeurope-01.azurewebsites.net",
};
