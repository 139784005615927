// DashboardHome.js

import React, { useState, useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  Bell,
  AlertCircle,
  Download,
  ChevronLeft,
  ChevronRight,
  Calendar,
} from "lucide-react";
import { UserNameContext } from "../../ProtectedRoute";

const EMISSIONS_DATA = [
  { month: "Jan", Emissions2023: 4000, Target2027: 3500 },
  { month: "Feb", Emissions2023: 3000, Target2027: 3400 },
  { month: "Mar", Emissions2023: 2800, Target2027: 3300 },
  { month: "Apr", Emissions2023: 3200, Target2027: 3100 },
  { month: "May", Emissions2023: 2900, Target2027: 3000 },
  { month: "Jun", Emissions2023: 2600, Target2027: 2900 },
  { month: "Jul", Emissions2023: 2700, Target2027: 2800 },
  { month: "Aug", Emissions2023: 3100, Target2027: 2700 },
  { month: "Sep", Emissions2023: 3300, Target2027: 2600 },
  { month: "Oct", Emissions2023: 3500, Target2027: 2500 },
  { month: "Nov", Emissions2023: 3400, Target2027: 2400 },
  { month: "Dec", Emissions2023: 3600, Target2027: 2300 },
];

const CAROUSEL_DATA = [
  {
    title: "Environment",
    items: [
      {
        key: "E1",
        label: "Climate Change Mitigation and Adaptation",
        value: 75,
      },
      { key: "E2", label: "Pollution", value: 60 },
      { key: "E3", label: "Water and Marine Resources", value: 85 },
      { key: "E4", label: "Biodiversity and Ecosystems", value: 70 },
      { key: "E5", label: "Resource Use and Circular Economy", value: 90 },
    ],
  },
  {
    title: "Social",
    items: [
      { key: "S1", label: "Governance & Oversight", value: 65 },
      { key: "S2", label: "Ethics & Compliance", value: 80 },
      { key: "S3", label: "Risk Management", value: 75 },
      { key: "S4", label: "Transparency & Reporting", value: 70 },
    ],
  },
  {
    title: "Governance",
    items: [{ key: "G1", label: "Corporate Governance Principles", value: 85 }],
  },
];

const UPLOAD_HISTORY = [
  "Energy Consumption 1",
  "Energy Consumption 2",
  "Energy Consumption 3",
  "Energy Consumption 4",
];

const ProgressBar = ({ value }) => (
  <div className="w-full bg-gray-200 rounded-full h-2 overflow-hidden">
    <div
      className="bg-green-600 h-2 rounded-full transition-all duration-500"
      style={{ width: `${value}%` }}
    />
  </div>
);

const Card = ({ children, className = "" }) => (
  <div
    className={`bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow ${className}`}
  >
    {children}
  </div>
);

const DashboardHome = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const dbUserName = useContext(UserNameContext); // Consume the username from context

  // Console log the user email and the name from DB
  if (user && dbUserName) {
    console.log("User Email:", user.email);
    console.log("User Name:", dbUserName);
  }

  const [currentSlide, setCurrentSlide] = useState(0);
  const [tasks, setTasks] = useState([]);

  const clientUrl = process.env.REACT_APP_CLIENT_URL;
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

  const authString = `${clientId}:${clientSecret}`;
  const encodedAuth = btoa(authString);

  useEffect(() => {
    const fetchTasks = async () => {
      if (!isAuthenticated || !user?.email) return; // Wait for user email and auth

      try {
        const payload = { string: user.email };
        const response = await axios.post(
          `${clientUrl}/ws/rest/getTasks`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${encodedAuth}`,
            },
          }
        );

        console.log("Task response:", response.data);

        // Check the shape of response.data
        if (Array.isArray(response.data) && response.data.length > 0) {
          // Assume the first element of data is an array of groups
          const groupsArray = response.data[0];
          if (Array.isArray(groupsArray)) {
            // Extract tasks
            const fetchedTasks = groupsArray.flatMap(
              (group) => group.tasks || []
            );
            setTasks(fetchedTasks);
          } else {
            console.error(
              "Expected response.data[0] to be an array, got:",
              groupsArray
            );
            setTasks([]);
          }
        } else {
          console.error(
            "response.data is not a non-empty array. Got:",
            response.data
          );
          setTasks([]);
        }
      } catch (error) {
        console.error("Error fetching tasks:", error.response || error.message);
      }
    };

    fetchTasks();
  }, [clientUrl, encodedAuth, isAuthenticated, user]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        Loading user information...
      </div>
    );
  }

  if (!isAuthenticated) {
    return null;
  }

  const nextSlide = () =>
    setCurrentSlide((prev) => (prev + 1) % CAROUSEL_DATA.length);
  const prevSlide = () =>
    setCurrentSlide(
      (prev) => (prev - 1 + CAROUSEL_DATA.length) % CAROUSEL_DATA.length
    );

  const getPriorityClasses = (priority) => {
    switch (priority?.toLowerCase()) {
      case "high":
        return "bg-red-100 text-red-800";
      case "medium":
        return "bg-yellow-100 text-yellow-800";
      case "low":
        return "bg-green-100 text-green-800";
      default:
        return "bg-gray-200 text-gray-700";
    }
  };

  const incompleteTasks = tasks.filter((task) => !task.completed).slice(0, 3);

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-green-50 to-white p-6 gap-6">
      {/* Welcome Section */}
      <div className="flex flex-col md:flex-row items-start">
        <div className="ml-0 md:ml-5">
          <h1 className="text-4xl font-semibold bg-gradient-to-r from-green-900 via-green-600 to-green-400 bg-clip-text text-transparent mb-2">
            Hello, {dbUserName}
          </h1>
          <p className="text-gray-700">Welcome to your GreenGen Dashboard 🌿</p>
        </div>
      </div>

      {/* Top Row */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Tasks Card */}
        <Card className="p-6">
          <h2 className="text-xl font-medium mb-4 text-gray-800">Your Tasks</h2>
          {incompleteTasks.length === 0 ? (
            <div className="text-gray-600">No incomplete tasks available.</div>
          ) : (
            <ul className="space-y-3 max-h-96 overflow-auto scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-400">
              {incompleteTasks.map((task, index) => {
                const dueDate = task.dueDate ? new Date(task.dueDate) : null;
                const formattedDate = dueDate
                  ? dueDate.toLocaleDateString(undefined, {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })
                  : null;

                return (
                  <li
                    key={index}
                    className="p-3 bg-white rounded-md border shadow-sm hover:shadow transition cursor-pointer"
                  >
                    <div className="flex flex-col space-y-1">
                      <span className="font-medium text-gray-800 truncate">
                        {task.title || task.name || `Task ${index + 1}`}
                      </span>
                      <div className="flex items-center justify-between text-sm text-gray-500 mt-1">
                        <div className="flex items-center gap-2">
                          <Calendar className="w-4 h-4 text-gray-400" />
                          {formattedDate ? (
                            formattedDate
                          ) : (
                            <span className="italic text-gray-400">
                              No due date
                            </span>
                          )}
                        </div>
                        {task.priority && (
                          <span
                            className={`inline-flex items-center rounded-full px-2 py-0.5 text-xs font-medium ${getPriorityClasses(
                              task.priority
                            )}`}
                          >
                            {task.priority}
                          </span>
                        )}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </Card>

        {/* Updates Card */}
        <Card className="p-6">
          <div className="flex items-center mb-4">
            <AlertCircle className="w-6 h-6 text-red-600 mr-2" />
            <h2 className="text-lg font-medium text-gray-800">
              5 rows need updating
            </h2>
          </div>
          <ul className="space-y-2 text-gray-700">
            <li className="p-2 hover:bg-green-100 rounded cursor-pointer">
              Source 1: Energy Consumption 1
            </li>
            <li className="p-2 hover:bg-green-100 rounded cursor-pointer">
              Source 2: Energy Consumption 2
            </li>
          </ul>
          <button className="mt-4 w-full bg-green-600 text-white py-2 rounded-lg hover:bg-green-700 transition-colors font-medium">
            View All
          </button>
        </Card>

        {/* Notifications Card */}
        <Card className="p-6">
          <div className="flex items-center mb-4">
            <Bell className="w-6 h-6 text-blue-600 mr-2" />
            <h2 className="text-lg font-medium text-gray-800">Notifications</h2>
          </div>
          <ul className="space-y-2 text-gray-700">
            <li className="p-2 hover:bg-green-100 rounded cursor-pointer">
              New documents uploaded for review.
            </li>
            <li className="p-2 hover:bg-green-100 rounded cursor-pointer">
              Reminder: Audit in 10 days.
            </li>
          </ul>
          <button className="mt-4 w-full bg-green-600 text-white py-2 rounded-lg hover:bg-green-700 transition-colors font-medium">
            View All
          </button>
        </Card>
      </div>

      {/* Middle Row - Chart */}
      <Card className="w-full p-6">
        <h2 className="text-lg font-medium text-gray-700 mb-4">
          Current vs Target Emissions
        </h2>
        <div style={{ width: "100%", height: 300 }}>
          <ResponsiveContainer>
            <AreaChart
              data={EMISSIONS_DATA}
              margin={{
                top: 5,
                right: 25,
                left: 0,
                bottom: 5,
              }}
            >
              <defs>
                <linearGradient id="colorEmissions" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#3b82f6" stopOpacity={0.25} />
                  <stop offset="95%" stopColor="#3b82f6" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorTarget" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#22c55e" stopOpacity={0.25} />
                  <stop offset="95%" stopColor="#22c55e" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid
                strokeDasharray="3 3"
                vertical={false}
                opacity={0.1}
                stroke="#718096"
              />
              <XAxis
                dataKey="month"
                axisLine={false}
                tickLine={false}
                tick={{ fill: "#718096", fontSize: 12 }}
                dy={10}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tick={{ fill: "#718096", fontSize: 12 }}
                dx={-10}
                tickFormatter={(value) => `${value.toLocaleString()}`}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: "#fff",
                  border: "none",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)",
                  padding: "8px 12px",
                }}
                itemStyle={{ fontSize: 12, color: "#4a5568" }}
                labelStyle={{
                  fontSize: 12,
                  fontWeight: "bold",
                  color: "#2d3748",
                }}
                formatter={(value) => [`${value.toLocaleString()}`, ""]}
                labelFormatter={(label) => `${label} 2023`}
              />
              <Legend
                wrapperStyle={{ paddingTop: "10px" }}
                iconType="circle"
                formatter={(value) => (
                  <span style={{ color: "#4a5568", fontSize: "12px" }}>
                    {value}
                  </span>
                )}
              />
              <Area
                type="monotone"
                dataKey="Emissions2023"
                stroke="#3b82f6"
                strokeWidth={2}
                fillOpacity={1}
                fill="url(#colorEmissions)"
                name="2023 Emissions"
                dot={false}
                activeDot={{
                  r: 4,
                  stroke: "#3b82f6",
                  strokeWidth: 2,
                  fill: "white",
                }}
              />
              <Area
                type="monotone"
                dataKey="Target2027"
                stroke="#22c55e"
                strokeWidth={2}
                fillOpacity={1}
                fill="url(#colorTarget)"
                name="2027 Target"
                dot={false}
                activeDot={{
                  r: 4,
                  stroke: "#22c55e",
                  strokeWidth: 2,
                  fill: "white",
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </Card>

      {/* Bottom Row */}
      <div className="flex flex-col md:flex-row gap-4">
        {/* Upload History */}
        <Card className="w-full md:w-1/3 p-6">
          <h2 className="text-lg font-medium text-gray-800 mb-4">
            Upload History
          </h2>
          <ul className="space-y-4">
            {UPLOAD_HISTORY.map((doc, index) => (
              <li
                key={index}
                className="flex items-center justify-between p-2 border-b text-gray-700"
              >
                <div className="flex items-center">
                  <div className="w-10 h-10 bg-blue-600 rounded-full flex items-center justify-center text-white mr-3">
                    {dbUserName
                      .split(" ")
                      .map((n) => n[0])
                      .join("")
                      .toUpperCase()}
                  </div>
                  <div>
                    <div className="font-medium">{doc}</div>
                    <div className="text-sm text-gray-500">CSV upload</div>
                  </div>
                </div>
                <Download className="text-green-600 cursor-pointer hover:text-green-700" />
              </li>
            ))}
          </ul>
        </Card>

        {/* Progress Section with Carousel */}
        <Card className="flex-1 p-6">
          <div className="relative">
            <div className="mb-6">
              <h2 className="text-xl font-medium text-gray-800">
                {CAROUSEL_DATA[currentSlide].title}
              </h2>
              <div className="space-y-4 mt-4">
                {CAROUSEL_DATA[currentSlide].items.map((item) => (
                  <div key={item.key} className="space-y-2">
                    <div className="flex justify-between">
                      <h6 className="text-sm font-medium text-gray-800">
                        {item.key}: {item.label}
                      </h6>
                      <span className="text-sm text-gray-500">
                        {item.value}%
                      </span>
                    </div>
                    <ProgressBar value={item.value} />
                  </div>
                ))}
              </div>
            </div>

            {/* Carousel Controls */}
            <div className="flex justify-center gap-4 mt-4">
              <button
                onClick={prevSlide}
                className="p-2 rounded-full hover:bg-gray-100 transition-colors disabled:opacity-50"
                disabled={currentSlide === 0}
              >
                <ChevronLeft
                  className={
                    currentSlide === 0 ? "text-gray-300" : "text-gray-600"
                  }
                />
              </button>
              <button
                onClick={nextSlide}
                className="p-2 rounded-full hover:bg-gray-100 transition-colors disabled:opacity-50"
                disabled={currentSlide === CAROUSEL_DATA.length - 1}
              >
                <ChevronRight
                  className={
                    currentSlide === CAROUSEL_DATA.length - 1
                      ? "text-gray-300"
                      : "text-gray-600"
                  }
                />
              </button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default DashboardHome;
