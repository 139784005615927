// LoginPage.js
import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import GreenGen from "../Assets/GreenGen.mp4";
import Loader from "../components/Loader";

const LoginPage = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) return;
    if (isAuthenticated) {
      // If already authenticated, navigate away from login
      navigate("/");
    }
  }, [isAuthenticated, isLoading, navigate]);

  if (isLoading) {
    return <Loader />;
  }

  const handleLogin = async () => {
    await loginWithRedirect();
  };

  return (
    <div className="min-h-screen flex items-center justify-center relative overflow-hidden">
      {/* Video Background */}
      <video
        autoPlay
        loop
        muted
        playsInline
        className="absolute inset-0 w-full h-full object-cover"
      >
        <source src={GreenGen} type="video/mp4" />
      </video>
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="relative z-10 w-full max-w-md p-8 bg-white bg-opacity-90 backdrop-filter backdrop-blur-lg shadow-lg rounded-lg mx-4"
      >
        <div className="flex justify-center mb-6">
          <img
            src="https://res.cloudinary.com/djjxmi9xc/image/upload/v1729087878/GreenGen_Logo_356_SOLO_xdbfwh.png"
            alt="GreenGen Logo"
            className="h-12 w-auto object-contain"
          />
        </div>
        <p className="text-center text-lg font-semibold text-gray-800 mb-2">
          Your CSRD Reporting Platform
        </p>
        <p className="text-center text-sm text-gray-600 mb-6">
          Connect • Collate • Comply
        </p>

        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={handleLogin}
          className="w-full bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded-md transition-colors duration-200 font-medium"
        >
          Proceed to GreenGen
        </motion.button>

        <div className="mt-6 text-xs text-gray-500">
          By submitting, you confirm your agreement to the storing and
          processing of your personal data by greengen.ai in accordance with our{" "}
          <button
            type="button"
            onClick={() => {}}
            className="underline hover:text-green-600 bg-transparent border-none p-0"
          >
            Privacy Statement
          </button>{" "}
          and{" "}
          <button
            type="button"
            onClick={() => {}}
            className="underline hover:text-green-600 bg-transparent border-none p-0"
          >
            T&amp;Cs
          </button>
          .
        </div>
      </motion.div>

      <div className="absolute bottom-4 text-center text-xs text-gray-200">
        By signing in, you agree to GreenGen's{" "}
        <button
          type="button"
          onClick={() => {}}
          className="underline hover:text-green-400 bg-transparent border-none p-0"
        >
          Terms of Service
        </button>{" "}
        and{" "}
        <button
          type="button"
          onClick={() => {}}
          className="underline hover:text-green-400 bg-transparent border-none p-0"
        >
          Privacy Policy
        </button>
        .
      </div>
    </div>
  );
};

export default LoginPage;
