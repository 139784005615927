import React, { useState } from "react";

const ESGForm = () => {
  const [stakeholders, setStakeholders] = useState([""]);
  const [internalStakeholders, setInternalStakeholders] = useState([
    "Employee",
    "Management",
  ]);
  const [externalStakeholders, setExternalStakeholders] = useState([
    "Investors",
    "Customers",
    "Communities",
  ]);
  const [internalEngagements, setInternalEngagements] = useState([""]);
  const [externalFeedbacks, setExternalFeedbacks] = useState([""]);

  const handleAddStakeholder = () => {
    setStakeholders([...stakeholders, ""]);
  };

  const handleStakeholderChange = (index, value) => {
    const newStakeholders = [...stakeholders];
    newStakeholders[index] = value;
    setStakeholders(newStakeholders);
  };

  const handleAddInternalEngagement = () => {
    setInternalEngagements([...internalEngagements, ""]);
  };

  const handleInternalEngagementChange = (index, value) => {
    const newEngagements = [...internalEngagements];
    newEngagements[index] = value;
    setInternalEngagements(newEngagements);
  };

  const handleAddExternalFeedback = () => {
    setExternalFeedbacks([...externalFeedbacks, ""]);
  };

  const handleExternalFeedbackChange = (index, value) => {
    const newFeedbacks = [...externalFeedbacks];
    newFeedbacks[index] = value;
    setExternalFeedbacks(newFeedbacks);
  };

  return (
    <form className="max-w-4xl mx-auto p-8 ">
      {/* Form Header */}
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-center text-gray-800">
          ESG Assessment Form
        </h1>
        <p className="text-center text-gray-600 mt-2">
          Please fill out the form below to help us understand your company's
          ESG practices.
        </p>
      </div>

      {/* Company Overview */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold mb-6 border-b pb-2 border-gray-300">
          Company Overview
        </h2>

        {/* Company Full Name */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            What is your company's full name?
          </label>
          <input
            type="text"
            name="companyFullName"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>

        {/* Headquarters Location */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Where are your headquarters located?
          </label>
          <input
            type="text"
            name="headquartersLocation"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>

        {/* Company Structure */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Please outline your company structure.
          </label>
          <textarea
            name="companyStructure"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>

        {/* Industry */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Which industry does your company operate in?
          </label>
          <input
            type="text"
            name="industry"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>

        {/* Primary Products/Services */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            What are your primary products/services?
          </label>
          <textarea
            name="primaryProducts"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>

        {/* Reporting Period and Turnover */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {/* Reporting Period */}
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Please confirm the reporting period (year).
            </label>
            <input
              type="number"
              name="reportingPeriod"
              className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            />
          </div>
          {/* Turnover */}
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Please confirm the turnover for the reporting period (year).
            </label>
            <input
              type="number"
              name="turnover"
              className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            />
          </div>
        </div>

        {/* Main Point of Contact */}
        <div className="mb-10">
          <h3 className="text-xl font-semibold mb-4">
            Who will be the main point of contact for this engagement?
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Name */}
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Name
              </label>
              <input
                type="text"
                name="mainContactName"
                className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
            {/* Title */}
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Title
              </label>
              <input
                type="text"
                name="mainContactTitle"
                className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
            {/* Email */}
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Email
              </label>
              <input
                type="email"
                name="mainContactEmail"
                className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
            {/* Telephone */}
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Telephone
              </label>
              <input
                type="tel"
                name="mainContactTelephone"
                className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
          </div>
        </div>

        {/* Secondary Point of Contact */}
        <div className="mb-10">
          <h3 className="text-xl font-semibold mb-4">
            Who will be the secondary point of contact?
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Name */}
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Name
              </label>
              <input
                type="text"
                name="secondaryContactName"
                className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
            {/* Title */}
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Title
              </label>
              <input
                type="text"
                name="secondaryContactTitle"
                className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
            {/* Email */}
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Email
              </label>
              <input
                type="email"
                name="secondaryContactEmail"
                className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
            {/* Telephone */}
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Telephone
              </label>
              <input
                type="tel"
                name="secondaryContactTelephone"
                className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
          </div>
        </div>

        {/* Market Position */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Can you briefly describe your company’s market position and
            competitive landscape?
          </label>
          <textarea
            name="marketPosition"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>

        {/* Auditors Contact Details */}
        <div className="mb-10">
          <h3 className="text-xl font-semibold mb-4">
            Please share your auditors' contact details with us
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Company Name */}
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Company Name
              </label>
              <input
                type="text"
                name="auditorCompanyName"
                className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
            {/* Contact Person */}
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Contact Person
              </label>
              <input
                type="text"
                name="auditorContactPerson"
                className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
            {/* Email */}
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Email
              </label>
              <input
                type="email"
                name="auditorEmail"
                className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
            {/* Telephone */}
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Telephone
              </label>
              <input
                type="tel"
                name="auditorTelephone"
                className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
          </div>
        </div>
      </section>

      {/* ESG Maturity and Culture */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold mb-6 border-b pb-2 border-gray-300">
          ESG Maturity and Culture
        </h2>

        {/* ESG Maturity Level */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            How would you describe your organization’s overall ESG maturity
            level?
          </label>
          <textarea
            name="esgMaturityLevel"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>

        {/* Senior Leadership Attitude */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            What is the general attitude of senior leadership toward ESG
            initiatives?
          </label>
          <textarea
            name="seniorLeadershipAttitude"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>

        {/* Employee Engagement */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            How engaged are employees in ESG initiatives?
          </label>
          <textarea
            name="employeeEngagement"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>

        {/* Training or Awareness Programs */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Do you offer any training or awareness programs?
          </label>
          <textarea
            name="trainingPrograms"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>

        {/* Culture of Accountability */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Do you feel there is a strong culture of accountability for ESG
            within your organization?
          </label>
          <textarea
            name="esgAccountability"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>
      </section>

      {/* Stakeholder Mapping and Influence */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold mb-6 border-b pb-2 border-gray-300">
          Stakeholder Mapping and Influence
        </h2>

        {/* Dynamic Stakeholders List */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-4">
            Can you provide a list of key stakeholders who influence your ESG
            strategy (e.g., investors, regulators, community groups)?
          </label>
          {stakeholders.map((stakeholder, index) => (
            <div key={index} className="flex items-center mb-3">
              <input
                type="text"
                value={stakeholder}
                onChange={(e) => handleStakeholderChange(index, e.target.value)}
                placeholder={`Stakeholder ${index + 1}`}
                className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddStakeholder}
            className="mt-2 flex items-center text-blue-600 hover:text-blue-800 font-medium"
          >
            <svg
              className="w-5 h-5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0V11H6a1 1 0 110-2h3V6a1 1 0 011-1z" />
            </svg>
            Add Another Stakeholder
          </button>
        </div>

        {/* Primary Concerns of Stakeholders */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            In your opinion, what are the primary concerns or expectations of
            these stakeholders?
          </label>
          <textarea
            name="stakeholderConcerns"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>

        {/* Prioritizing Stakeholder Concerns */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            How do you prioritize stakeholder concerns in your ESG
            decision-making process?
          </label>
          <textarea
            name="prioritizeConcerns"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>

        {/* Challenging Stakeholder Groups */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Are there any stakeholder groups that have a significant influence
            but are challenging to engage?
          </label>
          <textarea
            name="challengingStakeholders"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>
      </section>

      {/* Regulatory Landscape */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold mb-6 border-b pb-2 border-gray-300">
          Regulatory Landscape
        </h2>

        {/* Guidance from Regulatory Bodies */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Have you received any guidance or feedback from regulatory bodies on
            your ESG disclosures?
          </label>
          <textarea
            name="regulatoryGuidance"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>

        {/* Staying Informed About Regulatory Changes */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            How do you stay informed about regulatory changes, and who is
            responsible for monitoring compliance?
          </label>
          <textarea
            name="regulatoryMonitoring"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>
      </section>

      {/* ESG Strategy and Governance */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold mb-6 border-b pb-2 border-gray-300">
          ESG Strategy and Governance
        </h2>

        {/* Formal ESG Policy */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Do you currently have a formal ESG or sustainability policy in
            place? If yes, could you share a brief summary?
          </label>
          <textarea
            name="esgPolicy"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>

        {/* ESG Responsibilities Structure */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            How are ESG responsibilities structured within your organization
            (e.g., dedicated team, board-level oversight)?
          </label>
          <textarea
            name="esgResponsibilities"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>

        {/* ESG Policies Review Frequency */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            How often are your ESG policies reviewed and updated?
          </label>
          <textarea
            name="esgPoliciesReview"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="2"
          ></textarea>
        </div>

        {/* ESG Committee */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Do you have an ESG committee, and who are the key members involved?
          </label>
          <textarea
            name="esgCommittee"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="3"
          ></textarea>
        </div>
      </section>

      {/* ESG Reporting and Compliance */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold mb-6 border-b pb-2 border-gray-300">
          ESG Reporting and Compliance
        </h2>

        {/* ESG Reporting Frameworks */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Which ESG reporting frameworks do you currently follow (e.g., GRI,
            SASB, TCFD)?
          </label>
          <textarea
            name="esgFrameworks"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="2"
          ></textarea>
        </div>

        {/* ESG Reports Frequency */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            How frequently do you publish ESG reports, and what is the scope of
            these reports? (Please include a link to a previous report)
          </label>
          <textarea
            name="esgReportsFrequency"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="3"
          ></textarea>
        </div>

        {/* Specific Regulatory Obligations */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Are there any specific regulatory obligations (outside of ESG) that
            your company needs to comply with?
          </label>
          <textarea
            name="regulatoryObligations"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="3"
          ></textarea>
        </div>

        {/* Challenges in Compliance */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Have you faced any challenges in meeting these compliance
            requirements? If so, please elaborate.
          </label>
          <textarea
            name="complianceChallenges"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="3"
          ></textarea>
        </div>
      </section>

      {/* Continue with the rest of the sections following the same pattern */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold mb-6 border-b pb-2 border-gray-300">
          Materiality Assessment
        </h2>

        {/* ESG Strategy in Place */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            If you have an ESG strategy in place, please outline how you
            identified priority areas.
          </label>
          <textarea
            name="esgStrategyPriorityAreas"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>

        {/* Top Three ESG Issues */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            What are the top three ESG issues that you consider most material to
            your business?
          </label>
          <textarea
            name="topThreeEsgIssues"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="3"
          ></textarea>
        </div>

        {/* Incorporating Materiality Findings */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            How do you incorporate the findings of your materiality assessment
            into your overall strategy?
          </label>
          <textarea
            name="incorporateMaterialityFindings"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>

        {/* Stakeholder Engagement in Material ESG Topics */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            To date, have you engaged stakeholders when determining material ESG
            topics? If so, which stakeholders were involved?
          </label>
          <textarea
            name="stakeholderEngagementMaterialTopics"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>
      </section>

      {/* Data Management and Technology */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold mb-6 border-b pb-2 border-gray-300">
          Data Management and Technology
        </h2>

        {/* Confidence in ESG Data */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            How confident are you in the accuracy and reliability of your
            current ESG data?
          </label>
          <textarea
            name="esgDataConfidence"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="3"
          ></textarea>
        </div>

        {/* Challenges in Data Accuracy */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Are there any challenges you face in ensuring the accuracy and
            completeness of your ESG data?
          </label>
          <textarea
            name="esgDataChallenges"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>

        {/* Critical ESG Metrics Difficult to Measure */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Are there any critical ESG metrics you find difficult to measure
            (e.g., Scope 3 emissions)?
          </label>
          <textarea
            name="difficultEsgMetrics"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="3"
          ></textarea>
        </div>

        {/* Ensuring Data Quality Across Departments */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            How do you ensure the quality and consistency of data across
            different departments?
          </label>
          <textarea
            name="dataQualityAcrossDepartments"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>
      </section>

      {/* ESG Goals and Performance Metrics */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold mb-6 border-b pb-2 border-gray-300">
          ESG Goals and Performance Metrics
        </h2>

        {/* Short-term and Long-term ESG Goals */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            What are your company’s short-term (1-3 years) and long-term (5+
            years) ESG goals?
          </label>
          <textarea
            name="esgGoals"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="5"
          ></textarea>
        </div>

        {/* Alignment with Business Strategy */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            How do these goals align with your broader business strategy?
          </label>
          <textarea
            name="goalsAlignment"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>

        {/* KPIs for ESG Performance */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            What key performance indicators (KPIs) do you use to measure your
            ESG performance?
          </label>
          <textarea
            name="esgKPIs"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>

        {/* KPIs Difficult to Track */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Are there any specific KPIs you struggle to track or report on?
          </label>
          <textarea
            name="difficultKPIs"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="3"
          ></textarea>
        </div>
      </section>

      {/* Stakeholder Engagement */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold mb-6 border-b pb-2 border-gray-300">
          Stakeholder Engagement
        </h2>

        {/* Engagement with Internal Stakeholders */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-4">
            How do you engage with internal stakeholders on ESG issues?
          </label>
          {internalEngagements.map((engagement, index) => (
            <div key={index} className="flex items-center mb-3">
              <input
                type="text"
                value={engagement}
                onChange={(e) =>
                  handleInternalEngagementChange(index, e.target.value)
                }
                placeholder={`Internal Stakeholder ${index + 1}`}
                className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddInternalEngagement}
            className="mt-2 flex items-center text-blue-600 hover:text-blue-800 font-medium"
          >
            <svg
              className="w-5 h-5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0V11H6a1 1 0 110-2h3V6a1 1 0 011-1z" />
            </svg>
            Add Internal Stakeholder Engagement
          </button>
        </div>

        {/* Mechanisms to Gather Feedback from External Stakeholders */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-4">
            What mechanisms do you have in place to gather feedback from
            external stakeholders?
          </label>
          {externalFeedbacks.map((feedback, index) => (
            <div key={index} className="flex items-center mb-3">
              <input
                type="text"
                value={feedback}
                onChange={(e) =>
                  handleExternalFeedbackChange(index, e.target.value)
                }
                placeholder={`External Stakeholder ${index + 1}`}
                className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddExternalFeedback}
            className="mt-2 flex items-center text-blue-600 hover:text-blue-800 font-medium"
          >
            <svg
              className="w-5 h-5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0V11H6a1 1 0 110-2h3V6a1 1 0 011-1z" />
            </svg>
            Add External Stakeholder Feedback Mechanism
          </button>
        </div>

        {/* Stakeholder Groups Requiring More Engagement */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Are there any stakeholder groups you believe require more engagement
            or focus?
          </label>
          <textarea
            name="stakeholdersNeedMoreEngagement"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="3"
          ></textarea>
        </div>

        {/* Stakeholder Surveys Conducted */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Have you conducted any stakeholder surveys or consultations in the
            past year? If so, what were the key findings?
          </label>
          <textarea
            name="stakeholderSurveys"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>
      </section>

      {/* Pain Points and Challenges */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold mb-6 border-b pb-2 border-gray-300">
          Pain Points and Challenges
        </h2>

        {/* Biggest Challenges in Implementing ESG Initiatives */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            What are the biggest challenges your company faces in implementing
            ESG initiatives?
          </label>
          <textarea
            name="biggestChallenges"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>

        {/* Prioritizing ESG Issues with Limited Resources */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            How do you prioritize ESG issues when faced with limited resources?
          </label>
          <textarea
            name="prioritizeWithLimitedResources"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="3"
          ></textarea>
        </div>

        {/* Recent Incidents Impacting ESG Performance */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Are there any recent incidents or challenges that have impacted your
            ESG performance?
          </label>
          <textarea
            name="recentIncidents"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="3"
          ></textarea>
        </div>
      </section>

      {/* Business Model and Strategic Priorities */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold mb-6 border-b pb-2 border-gray-300">
          Business Model and Strategic Priorities
        </h2>

        {/* Anticipated Changes in Industry */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Are there any anticipated changes or disruptions in your industry
            that could impact your ESG strategy?
          </label>
          <textarea
            name="anticipatedIndustryChanges"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>

        {/* ESG Considerations Influencing Strategic Direction */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            How do you see ESG considerations influencing your strategic
            direction in the future?
          </label>
          <textarea
            name="esgInfluenceOnStrategy"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>

        {/* Specific Markets or Regions Where ESG Is Critical */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Are there any specific markets or regions where ESG considerations
            are more critical?
          </label>
          <textarea
            name="criticalMarketsRegions"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="3"
          ></textarea>
        </div>
      </section>

      {/* Sector-Specific ESG Risks and Opportunities */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold mb-6 border-b pb-2 border-gray-300">
          Sector-Specific ESG Risks and Opportunities
        </h2>

        {/* Sector-Specific ESG Risks */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            What sector-specific ESG risks have you identified as being most
            relevant to your business?
          </label>
          <textarea
            name="sectorSpecificRisks"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>

        {/* Emerging Opportunities */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Are there any emerging opportunities you are exploring, such as
            renewable energy or sustainable product innovations?
          </label>
          <textarea
            name="emergingOpportunities"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>

        {/* Influence on Business Operations */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            How do these risks and opportunities influence your current and
            future business operations?
          </label>
          <textarea
            name="risksOpportunitiesInfluence"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="4"
          ></textarea>
        </div>

        {/* Benchmarking Against Industry Peers */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Have you benchmarked your ESG performance against industry peers?
          </label>
          <textarea
            name="esgBenchmarking"
            className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-blue-500"
            rows="3"
          ></textarea>
        </div>
      </section>

      {/* Submit Button */}
      <div className="mt-10 text-center">
        <button
          type="submit"
          className="bg-blue-600 text-white font-semibold px-8 py-3 rounded-lg hover:bg-blue-700 transition duration-300"
        >
          Submit Form
        </button>
      </div>
    </form>
  );
};

export default ESGForm;
