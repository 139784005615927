import React, { useState, useEffect, useRef } from "react";
import {
  Folder,
  File as FileIcon,
  ChevronRight,
  ChevronDown,
  Plus,
  Upload,
  Download,
  User,
  Edit2,
  X,
  Trash,
  Grid,
  List as ListIcon,
} from "lucide-react";
import Loader from "../../components/Loader";
import axios from "axios";

const clientUrl = "https://miagendb9091-miagen.msappproxy.net"; // Adjust as needed
const GET_FILEREPO_ENDPOINT = `${clientUrl}/ws/rest/getFileRepo`;
const ADD_FOLDER_ENDPOINT = `${clientUrl}/ws/rest/addFolder`;
const ADD_FILE_ENDPOINT = `${clientUrl}/ws/rest/addFile`;
const GET_LEVELS_ENDPOINT = `${clientUrl}/ws/rest/getLevels`;

const getAuthHeaders = () => {
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
  const authString = `${clientId}:${clientSecret}`;
  const encodedAuth = btoa(authString);
  return {
    "Content-Type": "application/json",
    Authorization: `Basic ${encodedAuth}`,
  };
};

const UploadModal = ({ isOpen, onClose, onUpload, levels }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  // Tags and levels
  const [selectedTags, setSelectedTags] = useState([]);
  const [currentTag, setCurrentTag] = useState("");
  const [selectedLevelId, setSelectedLevelId] = useState("");

  const addTag = (e) => {
    e.preventDefault();
    if (currentTag.trim() && !selectedTags.includes(currentTag.trim())) {
      setSelectedTags([...selectedTags, currentTag.trim()]);
      setCurrentTag("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) return;

    setUploading(true);
    try {
      const fileData = {
        file: selectedFile,
        selectedTags,
        selectedLevelId: selectedLevelId || null,
        uploadedBy: "Colm Ducker (cducker@miagen.com)",
        uploadedAt: new Date().toISOString(),
      };

      await onUpload(fileData);
      setSelectedTags([]);
      setSelectedFile(null);
      setSelectedLevelId("");
      onClose();
    } catch (error) {
      console.error("Upload failed:", error);
      alert("Failed to register file.");
    } finally {
      setUploading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg p-6 w-full max-w-md shadow-xl relative">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
        >
          <X size={20} />
        </button>
        <h3 className="text-xl font-semibold text-gray-800 mb-4">
          Upload File
        </h3>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Select File
            </label>
            <input
              type="file"
              onChange={(e) => setSelectedFile(e.target.files[0])}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Add Tags (optional)
            </label>
            <div className="flex gap-2 mb-2">
              <input
                type="text"
                value={currentTag}
                onChange={(e) => setCurrentTag(e.target.value)}
                className="flex-1 px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter tag"
              />
              <button
                onClick={addTag}
                type="button"
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                Add
              </button>
            </div>

            <div className="flex flex-wrap gap-2">
              {selectedTags.map((tag, index) => (
                <span
                  key={index}
                  className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full flex items-center gap-2 text-sm"
                >
                  {tag}
                  <button
                    type="button"
                    onClick={() =>
                      setSelectedTags(selectedTags.filter((t) => t !== tag))
                    }
                    className="hover:text-blue-600"
                  >
                    <X size={14} />
                  </button>
                </span>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Select Level
            </label>
            <select
              value={selectedLevelId}
              onChange={(e) => setSelectedLevelId(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">-- Select Level --</option>
              {levels.map((level) => (
                <option key={level.id} value={level.id}>
                  {level.name}
                </option>
              ))}
            </select>
          </div>

          <button
            type="submit"
            disabled={uploading}
            className={`w-full py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors ${
              uploading ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {uploading ? "Uploading..." : "Upload"}
          </button>
        </form>
      </div>
    </div>
  );
};

const Item = ({
  item,
  level,
  isExpanded,
  onToggle,
  onSelect,
  isSelected,
  onRename,
  onDelete,
  onDownload,
  viewMode,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editName, setEditName] = useState(item.name);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing]);

  const saveName = () => {
    if (editName.trim()) {
      onRename(item.id, editName.trim());
      setIsEditing(false);
    }
  };

  if (viewMode === "tile") {
    return (
      <div
        onClick={() => !isEditing && onSelect(item)}
        className={`relative group bg-white border rounded-md p-4 hover:shadow-md cursor-pointer ${
          isSelected ? "ring-2 ring-blue-300" : ""
        } transition`}
      >
        <div className="flex items-center space-x-2">
          {item.type === "folder" ? (
            <Folder className="text-yellow-500" size={24} />
          ) : (
            <FileIcon size={24} />
          )}
          {isEditing ? (
            <input
              ref={inputRef}
              type="text"
              value={editName}
              onChange={(e) => setEditName(e.target.value)}
              onBlur={saveName}
              onKeyDown={(e) => e.key === "Enter" && saveName()}
              className="px-2 py-1 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 text-sm flex-1"
              onClick={(e) => e.stopPropagation()}
            />
          ) : (
            <span className="font-medium text-gray-800 flex-1">
              {item.name}
            </span>
          )}
        </div>
        {!isEditing && (
          <div className="absolute top-2 right-2 flex items-center gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
            {item.type === "file" && onDownload && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onDownload(item);
                }}
                className="p-1 hover:bg-gray-100 rounded-md"
              >
                <Download size={14} />
              </button>
            )}
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsEditing(true);
              }}
              className="p-1 hover:bg-gray-100 rounded-md"
            >
              <Edit2 size={14} />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onDelete(item.id);
              }}
              className="p-1 hover:bg-gray-100 rounded-md text-red-500"
            >
              <Trash size={14} />
            </button>
          </div>
        )}
      </div>
    );
  }

  // List mode
  return (
    <div
      className={`select-none hover:bg-gray-100 transition-colors rounded-md ${
        isSelected ? "bg-blue-50" : ""
      }`}
      style={{ paddingLeft: `${level * 20}px` }}
      onClick={() => !isEditing && onSelect(item)}
    >
      <div className="flex items-center p-2 group relative">
        {item.type === "folder" ? (
          <>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onToggle(item.id);
              }}
              className="mr-1 text-gray-600 hover:text-gray-800"
            >
              {isExpanded ? (
                <ChevronDown size={18} />
              ) : (
                <ChevronRight size={18} />
              )}
            </button>
            <Folder className="text-yellow-500" size={18} />
          </>
        ) : (
          <FileIcon size={18} />
        )}
        {isEditing ? (
          <input
            ref={inputRef}
            type="text"
            value={editName}
            onChange={(e) => setEditName(e.target.value)}
            onBlur={saveName}
            onKeyDown={(e) => e.key === "Enter" && saveName()}
            className="ml-2 px-2 py-1 border border-gray-300 rounded-md w-32 focus:ring-2 focus:ring-blue-500 text-sm"
            onClick={(e) => e.stopPropagation()}
          />
        ) : (
          <span className="ml-2 font-medium text-gray-800">{item.name}</span>
        )}
        {!isEditing && (
          <div className="absolute right-2 flex items-center gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
            {item.type === "file" && onDownload && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onDownload(item);
                }}
                className="p-1 hover:bg-gray-200 rounded-md"
              >
                <Download size={14} />
              </button>
            )}
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsEditing(true);
              }}
              className="p-1 hover:bg-gray-200 rounded-md"
            >
              <Edit2 size={14} />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onDelete(item.id);
              }}
              className="p-1 hover:bg-gray-200 rounded-md text-red-500"
            >
              <Trash size={14} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const FileRepository = () => {
  const [data, setData] = useState(null);
  const [expandedFolders, setExpandedFolders] = useState(new Set());
  const [selectedItem, setSelectedItem] = useState(null);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");

  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState("list"); // "list" or "tile"
  const [currentFolder, setCurrentFolder] = useState(null);
  const [currentPath, setCurrentPath] = useState([]);

  const user = { email: "cducker@miagen.com" }; // Adjust user info as needed
  const [levels, setLevels] = useState([]);

  const fetchFileRepo = async () => {
    setLoading(true);
    try {
      const headers = getAuthHeaders();
      const response = await axios.post(GET_FILEREPO_ENDPOINT, {}, { headers });
      return response.data;
    } catch (error) {
      console.error("Failed to fetch file repository:", error);
      alert("Failed to load file repository.");
      return null;
    } finally {
      setLoading(false);
    }
  };

  const fetchLevels = async () => {
    console.log("Fetching levels from API...");
    const encodedAuth = btoa(
      `${process.env.REACT_APP_CLIENT_ID}:${process.env.REACT_APP_CLIENT_SECRET}`
    );
    const response = await fetch(GET_LEVELS_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${encodedAuth}`,
      },
      body: JSON.stringify({ string: user.email }),
    });
    const levelsData = await response.json();
    setLevels(levelsData.levels || []);
  };

  useEffect(() => {
    (async () => {
      await fetchLevels();
      const repo = await fetchFileRepo();
      if (repo) {
        setData(repo);
        setCurrentFolder(repo);
        setCurrentPath([{ id: repo.id, name: repo.name }]);
        setExpandedFolders(new Set([repo.id]));
      }
    })();
  }, []);

  useEffect(() => {
    if (viewMode === "list" && data) {
      setCurrentFolder(data);
      setCurrentPath([{ id: data.id, name: data.name }]);
    }
  }, [viewMode, data]);

  const handleSelect = (item) => {
    setSelectedItem(item);
    if (viewMode === "tile" && item.type === "folder") {
      setCurrentFolder(item);
      setCurrentPath((prev) => [...prev, { id: item.id, name: item.name }]);
      setSelectedItem(null);
    }
  };

  const handleBreadcrumbClick = (index) => {
    const newPath = currentPath.slice(0, index + 1);
    setCurrentPath(newPath);

    const findFolderById = (node, id) => {
      if (node.id === id) return node;
      if (node.children) {
        for (const child of node.children) {
          const found = findFolderById(child, id);
          if (found) return found;
        }
      }
      return null;
    };

    const targetId = newPath[newPath.length - 1].id;
    const folder = findFolderById(data, targetId);
    setSelectedItem(null);
    setCurrentFolder(folder);
  };

  const toggleFolder = (id) => {
    setExpandedFolders((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const handleRename = (id, newName) => {
    const updateName = (node) => {
      if (node.id === id) {
        node.name = newName;
        return;
      }
      node.children?.forEach(updateName);
    };

    if (data) {
      const newData = { ...data };
      updateName(newData);
      setData(newData);

      if (selectedItem && selectedItem.id === id) {
        setSelectedItem({ ...selectedItem, name: newName });
      }
    }
  };

  const handleDelete = (id) => {
    if (!window.confirm("Are you sure you want to delete this item?")) return;
    const deleteItem = (node) => {
      node.children = node.children?.filter((child) => {
        if (child.id === id) return false;
        deleteItem(child);
        return true;
      });
    };

    if (data) {
      const newData = { ...data };
      deleteItem(newData);
      setData(newData);

      if (selectedItem?.id === id) {
        setSelectedItem(null);
      }
    }
  };

  const handleDownload = (file) => {
    if (file.fileData) {
      const url = URL.createObjectURL(file.fileData);
      const a = document.createElement("a");
      a.href = url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } else {
      alert("No file data available to download.");
    }
  };

  const handleUpload = async (fileData) => {
    setLoading(true);
    try {
      const headers = getAuthHeaders();

      const mainFileName = fileData.file.name || "Untitled";
      const fileExtension = mainFileName.includes(".")
        ? mainFileName.substring(mainFileName.lastIndexOf("."))
        : ".dat";
      const mime = fileData.file.type || "application/octet-stream";
      const sizeInMB = (fileData.file.size / 1024 / 1024).toFixed(2);
      const folder_id = currentFolder ? currentFolder.id : data ? data.id : "4";

      const tags =
        fileData.selectedTags.length > 0
          ? fileData.selectedTags.map((t) => ({ section: "csrd", key: t }))
          : [
              { section: "csrd", key: "E1" },
              { section: "objective", key: "1" },
            ];

      const level_id = fileData.selectedLevelId
        ? [fileData.selectedLevelId]
        : ["1"];

      const payload = {
        folder_id: folder_id,
        name: "Task Name",
        rolls_up_to: "2",
        files: [
          {
            id: "",
            file_name: mainFileName,
            extension: fileExtension,
            mime: mime,
            size: sizeInMB,
            download_uri: "www.test.com",
            user_email: "cducker@miagen.com",
            tags: tags,
            level_id: level_id,
          },
        ],
      };

      await axios.post(ADD_FILE_ENDPOINT, payload, { headers });

      // After adding the file, re-fetch the entire repo
      const repo = await fetchFileRepo();
      if (repo) {
        setData(repo);
        // Reset currentFolder and path according to the current view mode
        if (viewMode === "list") {
          setCurrentFolder(repo);
          setCurrentPath([{ id: repo.id, name: repo.name }]);
          setExpandedFolders(new Set([repo.id]));
        } else {
          const findFolderById = (node, id) => {
            if (node.id === id) return node;
            if (node.children) {
              for (const child of node.children) {
                const found = findFolderById(child, id);
                if (found) return found;
              }
            }
            return null;
          };

          const targetFolder = findFolderById(repo, folder_id);
          if (targetFolder) {
            setCurrentFolder(targetFolder);
          } else {
            setCurrentFolder(repo);
            setCurrentPath([{ id: repo.id, name: repo.name }]);
            setExpandedFolders(new Set([repo.id]));
          }
        }
        alert("File registered successfully!");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to register file.");
    } finally {
      setLoading(false);
    }
  };

  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) return;
    // Implement the add folder logic
    try {
      setLoading(true);
      const headers = getAuthHeaders();
      const payload = {
        parent_id: currentFolder ? currentFolder.id : data.id,
        folder_name: newFolderName.trim(),
      };
      await axios.post(ADD_FOLDER_ENDPOINT, payload, { headers });
      const repo = await fetchFileRepo();
      if (repo) {
        setData(repo);
        if (viewMode === "list") {
          setCurrentFolder(repo);
          setCurrentPath([{ id: repo.id, name: repo.name }]);
          setExpandedFolders(new Set([repo.id]));
        } else {
          const findFolderById = (node, id) => {
            if (node.id === id) return node;
            if (node.children) {
              for (const child of node.children) {
                const found = findFolderById(child, id);
                if (found) return found;
              }
            }
            return null;
          };

          const folder_id = currentFolder ? currentFolder.id : repo.id;
          const targetFolder = findFolderById(repo, folder_id);
          if (targetFolder) {
            setCurrentFolder(targetFolder);
          } else {
            setCurrentFolder(repo);
            setCurrentPath([{ id: repo.id, name: repo.name }]);
            setExpandedFolders(new Set([repo.id]));
          }
        }
      }
      alert("Folder created successfully!");
    } catch (error) {
      console.error("Error creating folder:", error);
      alert("Failed to create folder.");
    } finally {
      setNewFolderName("");
      setIsCreatingFolder(false);
      setLoading(false);
    }
  };

  const renderTree = (node, level = 0) => {
    if (!node) return null;
    return (
      <div key={node.id}>
        <Item
          item={node}
          level={level}
          isExpanded={expandedFolders.has(node.id)}
          onToggle={toggleFolder}
          onSelect={handleSelect}
          isSelected={selectedItem?.id === node.id}
          onRename={handleRename}
          onDelete={handleDelete}
          onDownload={handleDownload}
          viewMode={viewMode}
        />
        {node.type === "folder" &&
          node.children &&
          node.children.length > 0 &&
          expandedFolders.has(node.id) &&
          node.children.map((child) => renderTree(child, level + 1))}
      </div>
    );
  };

  const renderTiles = (folder) => {
    if (!folder || !folder.children || folder.children.length === 0) {
      return (
        <div className="text-gray-500 text-sm">No items in this folder.</div>
      );
    }

    return (
      <div className="grid grid-cols-3 gap-4">
        {folder.children.map((child) => (
          <Item
            key={child.id}
            item={child}
            level={0}
            isExpanded={false}
            onToggle={() => {}}
            onSelect={handleSelect}
            isSelected={selectedItem?.id === child.id}
            onRename={handleRename}
            onDelete={handleDelete}
            onDownload={handleDownload}
            viewMode={viewMode}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="h-screen flex overflow-hidden bg-white">
      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
          <Loader />
        </div>
      )}

      {/* Left Column */}
      <div className="w-2/3 border-r p-4 flex flex-col space-y-4 overflow-auto">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold text-gray-800">
            File Repository
          </h2>
          <div className="flex items-center gap-2">
            <button
              onClick={() => setViewMode(viewMode === "list" ? "tile" : "list")}
              className="p-2 hover:bg-gray-100 rounded-md transition"
              title="Toggle View Mode"
            >
              {viewMode === "list" ? (
                <Grid size={16} />
              ) : (
                <ListIcon size={16} />
              )}
            </button>
            <button
              onClick={() => setIsUploadModalOpen(true)}
              className="flex items-center gap-2 px-3 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition"
            >
              <Upload size={16} />
              Upload File
            </button>
            <button
              onClick={() => setIsCreatingFolder(true)}
              className="flex items-center gap-2 px-3 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition"
            >
              <Plus size={16} />
              New Folder
            </button>
          </div>
        </div>

        {isCreatingFolder && (
          <div className="bg-gray-50 p-3 rounded-md border space-y-2">
            <input
              type="text"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              placeholder="New folder name"
              className="px-3 py-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <div className="flex justify-end gap-2">
              <button
                onClick={handleCreateFolder}
                className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition"
              >
                Create
              </button>
              <button
                onClick={() => setIsCreatingFolder(false)}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition"
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {viewMode === "tile" && currentPath.length > 0 && (
          <div className="flex items-center text-sm text-gray-600 space-x-1">
            {currentPath.map((crumb, i) => (
              <React.Fragment key={crumb.id}>
                <button
                  onClick={() => handleBreadcrumbClick(i)}
                  className="text-blue-600 hover:underline"
                >
                  {crumb.name}
                </button>
                {i < currentPath.length - 1 && (
                  <span className="text-gray-400">/</span>
                )}
              </React.Fragment>
            ))}
          </div>
        )}

        <div className="flex-1 overflow-auto bg-gray-50 border rounded-md p-2">
          {viewMode === "list"
            ? data
              ? renderTree(data)
              : "Loading..."
            : currentFolder
            ? renderTiles(currentFolder)
            : "Loading..."}
        </div>
      </div>

      {/* Right Column: Detail View */}
      <div
        className={`w-1/3 p-6 overflow-auto transition-transform duration-300 ${
          selectedItem ? "translate-x-0" : "translate-x-full"
        } relative border-l`}
      >
        {selectedItem ? (
          <div className="max-w-lg mx-auto">
            <button
              onClick={() => setSelectedItem(null)}
              className="absolute top-6 right-6 p-2 hover:bg-gray-100 rounded-full"
              title="Close"
            >
              <X className="w-5 h-5 text-gray-600" />
            </button>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              {selectedItem.name}
            </h2>

            {selectedItem.type === "folder" ? (
              <div className="space-y-2 text-gray-700">
                <p>
                  This is a folder. You can upload files here or navigate inside
                  it.
                </p>
                <p className="text-sm text-gray-500 italic">
                  Folder ID: {selectedItem.id}
                </p>
              </div>
            ) : (
              // File details
              <div className="space-y-4 text-gray-700">
                <div className="flex items-center gap-2 text-sm text-gray-600">
                  <User size={14} />
                  <span>
                    Uploaded by:{" "}
                    {selectedItem.uploadedBy ||
                      "Colm Ducker (cducker@miagen.com)"}{" "}
                    at{" "}
                    {selectedItem.uploadedAt
                      ? new Date(selectedItem.uploadedAt).toLocaleString()
                      : "Unknown"}
                  </span>
                </div>
                <div className="text-sm text-gray-600">
                  Size: {selectedItem.size || "N/A"}
                </div>
                {selectedItem.tags && selectedItem.tags.length > 0 && (
                  <div className="flex flex-wrap gap-2">
                    {selectedItem.tags.map((tagObj, i) => (
                      <span
                        key={i}
                        className="px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-xs"
                      >
                        {tagObj.section}: {tagObj.key}
                      </span>
                    ))}
                  </div>
                )}

                <div className="flex gap-2 mt-4">
                  {selectedItem.type === "file" && (
                    <button
                      onClick={() => handleDownload(selectedItem)}
                      className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 flex items-center gap-2 text-sm"
                    >
                      <Download size={16} /> Download
                    </button>
                  )}
                  <button
                    onClick={() => handleDelete(selectedItem.id)}
                    className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 flex items-center gap-2 text-sm"
                  >
                    <Trash size={16} /> Delete
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="text-gray-500 flex items-center justify-center h-full">
            Select a file or folder to view its details
          </div>
        )}
      </div>

      {/* Upload Modal */}
      <UploadModal
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
        onUpload={handleUpload}
        levels={levels}
      />
    </div>
  );
};

export default FileRepository;
