// LogoutButton.js
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();

    logout({
      returnTo: window.location.origin + "/login",
    });
  };

  return (
    <button
      onClick={handleLogout}
      className="text-gray-700 hover:bg-red-100 p-2 rounded-full focus:outline-none transition-colors duration-300"
    >
      {/* You can also use an icon here if you prefer */}
      Logout
    </button>
  );
};

export default LogoutButton;
